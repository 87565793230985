import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { DownloadReviewFileByFileName, ReviewBaseUrl } from "../../services/BaseService";
import { PropertyService } from "../../services/PropertyService";
import { ReviewService } from "../../services/ReviewService";
import { ReviewTagService } from '../../services/ReviewTagService';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "application/pdf", "application/msword"];
const maxAllowedFiles = 5;
const maxAllowedFilesMessage = 'Maximum ' + maxAllowedFiles + ' files allowed!';

const schema = yup.object().shape({
    firstName: yup.string().required('Please enter firstname'),
    lastName: yup.string().required('Please enter lastname'),
    address: yup.string().required('Please enter address'),
    zipcode: yup.string().required('Please enter zipcode'),
    countryId: yup.number().required('Please select country'),
    stateId: yup.number().required('Please select state'),
    cityId: yup.number().required('Please select city'),
    drivingLicenseNumber: yup.string().required('Please enter driving license number'),
    email: yup.string().required('Please enter email').email("Enter valid email"),
    mobile: yup.string().required('Please enter mobile'),
    reviewTags: yup.array(), //.required('Please select reviewtag'),
    reviewText: yup.string().required('Please enter review'),
    files: yup.array().max(maxAllowedFiles, maxAllowedFilesMessage).test('fileType', "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf and .doc", (value, event) => {
        let IsValid = true;
        if (value) {
            for (var i = 0; i < value.length; i++) {
                if (!SUPPORTED_FORMATS.includes(value[i].type)) {
                    IsValid = false;
                }
            }
        } else {
            IsValid = false;
        }
        return IsValid;
    })
});

export default function EditReview(props) {
  
    const [data, setData] = useState({
        guestReviewId: '',
        firstName: '',
        lastName: '',
        address: '',
        zipcode: '',
        countryId: '',
        stateId: '',
        cityId: '',
        drivingLicenseNumber: '',
        email: '',
        mobile: '',
        reviewTags: '',
        reviewText: '',
        createdByPropertyId: authenticationService.currentUserValue.propertyId,
        reviewByPropertyId: authenticationService.currentUserValue.propertyId,
        reviewByUserId: authenticationService.currentUserValue.userId,
        createdByUserId: authenticationService.currentUserValue.userId,
        reviewFiles: [],
        guestId: '',
        IsActive: true,
        files: [],
        deletedFiles: []
    });
    
    const [options, setOptions] = useState([]);
    const animatedComponents = makeAnimated();
    const [selectedOption, setSelectedOption] = useState([]);

    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const [loading, setLoading] = React.useState(true);
    const [countryCollection, setCountryCollection] = useState([{ label: "Loading ...", value: "" }]);
    const [stateCollection, setStateCollection] = useState([]);
    const [cityCollection, setCityCollection] = useState([]);
    const [fileCollection, setFileCollection] = useState([]);
    const [reviewTagCollection, setReviewTagCollection] = useState([]);
    let history = useHistory();
    const reviewId = props.match.params.id;

    useEffect(() => {
        props.addCurrentLink(window.location.pathname);
        getReviewData();

        async function getReviewData() {
            const response = await ReviewService.getReviewData(reviewId);
            const rData = response.data.tResult;
            data.guestReviewId = rData.guestReviewId;
            data.firstName = rData.firstName;
            data.lastName = rData.lastName;
            data.address = rData.address;
            data.zipcode = rData.zipCode;
            data.countryId = rData.countryId;
            data.stateId = rData.stateId;
            data.cityId = rData.cityId;
            data.drivingLicenseNumber = rData.drivingLicenseNumber;
            data.email = rData.email;
            data.mobile = rData.mobile;
            data.reviewTags = (rData.reviewTags != null && rData.reviewTags != undefined && rData.reviewTags.length > 0) ? JSON.parse(rData.reviewTags) : [];
            data.reviewText = rData.reviewText;
            //data.file: null,
            data.IsActive = rData.isActive;
            data.reviewFiles = rData.guestReviewFileCollection;
            data.guestId = rData.guestId;
            //setLoading(false);
            getCountryList();
            getReviewTagList();                       
        }

        

        async function getCountryList() {
            const response = await PropertyService.getCountryList();
            setCountryCollection(response.data.countryCollection.tListResult);
            setLoading(false);

            if (parseInt(data.countryId) > 0) {
                getStateList(data.countryId);
            }

            if (parseInt(data.stateId) > 0) {
                getCityList(data.stateId);
            }
        }

        async function getReviewTagList() {
            const response = await ReviewTagService.getReviewTagList();
            setReviewTagCollection(response.data);

            let tagOptions = [];
            response.data.forEach((tag) => {
                tagOptions.push({ value: tag.tagName, label: tag.tagName });
            });
            setOptions(tagOptions);

            if (data.reviewTags != null && data.reviewTags != undefined && data.reviewTags.length > 0) {
                let tagOptions = [];
                data.reviewTags.forEach((tag) => {
                    tagOptions.push({ value: tag, label: tag });
                });
                setSelectedOption(tagOptions);
            }
        }

    }, []);

    async function getStateList(countryId) {
        const response = await PropertyService.getStateList(countryId);
        setStateCollection(response.data.stateCollection.tListResult);
    }

    async function getCityList(stateId) {
        const response = await PropertyService.getCityList(stateId);
        setCityCollection(response.data.cityCollection.tListResult);
    }

    const handleCountryChange = (form, event) => {
        data.stateId = '';
        data.cityId = '';

        form.setFieldValue('stateId', '');
        form.setFieldValue('cityId', '');

        setCityCollection([]);
        getStateList(event.target.value);
    };

    const handleStateChange = (form, event) => {
        data.cityId = '';
        form.setFieldValue('cityId', '');
        getCityList(event.target.value);
    };

    const handleFileUploadChange = (event, form) => {
        setFileCollection(event.target.files);
        let fileNames = [];
        for (var i = 0; i < event.currentTarget.files.length; i++) {
            fileNames.push({ name: event.currentTarget.files[i].name, type: event.currentTarget.files[i].type });
        }
        form.setFieldValue('files', fileNames);

        // To include already stored files for file upload validation
        if (data.reviewFiles.length > 0) {
            data.reviewFiles.forEach((item) => {
                var fileExt = item.fileName.slice((item.fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                for (var i = 0, length = SUPPORTED_FORMATS.length; i < length; i++) {
                    var format = SUPPORTED_FORMATS[i];
                    if (format.includes(fileExt)) {
                        fileNames.push({ name: item.fileName, type: format });
                        break;
                    } else if (item.fileType == 2) {
                        fileNames.push({ name: item.fileName, type: "application/msword" });
                        break;
                    }
                }
            });
        }

        data.files = fileNames;
    };

    const handleSubmit = (values, actions) => {
        if (data.files.length == 0 && fileCollection.length == 0 && data.reviewFiles.length == 0) {
            Swal.fire({
                title: 'Choose files',
                text: "Choose at least 1 file!",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            }).then((result) => {
                
            })
            return;
        }
        //debugger;
        if (selectedOption.length == 0) {
            Swal.fire({
                title: 'Review Tag!',
                text: "Please select reviewtag",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return;
        }

        const formData = new FormData();
        for (var index = 0; index < fileCollection.length; index++) {
            formData.append('file', fileCollection[index]);
        }
        var objReviesData = Object.assign({}, values);
        //objReviesData.reviewTags = JSON.stringify(values.reviewTags);
        objReviesData.reviewTags = JSON.stringify(selectedOption.map(option => option.label));
        formData.append('Review', JSON.stringify(objReviesData));
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        axios.put(ReviewBaseUrl, formData, { headers: headers }).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success == true) {
                    //setShowHide(true);
                    //setMessage('Record updated successfully!');
                    //setTitle('Success');
                    history.push("/reviews");
                } else {
                    //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
                    setShowHide(true);
                    setMessage('Error while trying to update review');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data && error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    }

    const deleteFile = (guestReviewFile, index, form, val) => {
        
        
        Swal.fire({
            title: 'Delete!',
            text: "Are you sure want to delete \'" + guestReviewFile.fileName + "\'?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value == true) {

                //deleteFileOnServer(guestReviewFile.guestReviewFileId);
                
                val.deletedFiles.push(guestReviewFile);
                val.reviewFiles.splice(index, 1);
                form.setFieldValue('reviewFiles', val.reviewFiles);
                //data.files = val.reviewFiles;

                // To delete form list for file upload validation
                if (data.files.length > 0) {
                    for (var i = 0, length = data.files.length; i < length; i++) {
                        if (data.files[i].name == guestReviewFile.fileName) {
                            data.files.splice(i, 1);
                            break;
                        }
                    }
                }

                if (val.reviewFiles.length == 0) {
                    form.setFieldValue('files', '');
                }

                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
            }
        })        
    }
    async function deleteFileOnServer(guestReviewFileId) {
        //debugger;
        var response = await ReviewService.deleteGuestReviewFile(guestReviewFileId);
        Swal.fire(
            'Deleted!',
            response.data.message,
            response.data.success == true?'success':'error'
        );
    }
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <BreadcrumbsNav url="/reviews" urlText="Reviews" typographyText="Update Review" />

                {/*<div className="row">*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <h6 className="m-0 font-weight-bold text-primary float-start">Update Review</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <RouteLink to={"/reviews"} className="float-end">*/}
                {/*            <Button> Review List </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="card-body">
            
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">

                                <Field name="firstName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control type="text" {...field} id="firstName" isInvalid={!!errors.firstName} autoFocus/>
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="lastName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.lastName && touched.lastName} as={Col}>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" {...field} id="lastName" isInvalid={!!errors.lastName} />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <Row className="mb-3">

                                <Field name="address">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.address && touched.address} as={Col}>
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" {...field} id="address" isInvalid={!!errors.address} />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="zipcode">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.zipcode && touched.zipcode} as={Col}>
                                            <Form.Label>Zipcode</Form.Label>
                                            <Form.Control type="text" {...field} id="zipcode" isInvalid={!!errors.zipcode} />
                                            <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <Row className="mb-3">

                                <Field name="countryId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.countryId && touched.countryId} as={Col} onChange={(e) => { handleCountryChange(form,e); }}>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Select {...field} id="countryId" isInvalid={!!errors.countryId} disabled={loading} >

                                                <option key="0" value="0">Please select country</option>

                                                {countryCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.countryId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="stateId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.stateId && touched.stateId} as={Col} onChange={(e) => { handleStateChange(form,e); }}>
                                            <Form.Label>State</Form.Label>
                                            <Form.Select {...field} id="stateId" isInvalid={!!errors.stateId} disabled={loading} >
                                                <option key="0" value="0">Please select state</option>
                                                {stateCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <Row className="mb-3">

                                <Field name="cityId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.cityId && touched.cityId} as={Col}>
                                            <Form.Label>City</Form.Label>
                                            <Form.Select {...field} id="cityId" isInvalid={!!errors.cityId} disabled={loading} >
                                                <option key="0" value="0">Please select city</option>
                                                {cityCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.cityId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="drivingLicenseNumber">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.drivingLicenseNumber && touched.drivingLicenseNumber} as={Col}>
                                            <Form.Label>Driving license number</Form.Label>
                                            <Form.Control type="text" {...field} id="drivingLicenseNumber" isInvalid={!!errors.drivingLicenseNumber} />
                                            <Form.Control.Feedback type="invalid">{errors.drivingLicenseNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">

                                <Field name="email">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.email && touched.email} as={Col}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" {...field} id="email" isInvalid={!!errors.email} />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="mobile">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.mobile && touched.mobile} as={Col}>
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control type="text" {...field} id="mobile" isInvalid={!!errors.mobile} />
                                            <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                                <Field name="reviewTags">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.reviewTags && touched.reviewTags} as={Col}>
                                            <Form.Label>Review Tag</Form.Label>
                                            {/*<Form.Select multiple={true} {...field} id="reviewTags" isInvalid={!!errors.reviewTags}>*/}
                                            {/*    {reviewTagCollection.map((item, i) => {*/}
                                            {/*        return (<option key={i} value={item.tagName}>{item.tagName}</option>)*/}
                                            {/*    })}*/}
                                            {/*</Form.Select>*/}
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                options={options}
                                                id="reviewTags"
                                                value={selectedOption}
                                                onChange={setSelectedOption} />
                                            <Form.Control.Feedback type="invalid">{errors.reviewTags}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="reviewText">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.reviewText && touched.reviewText} as={Col}>
                                            <Form.Label>Review</Form.Label>
                                            <Form.Control as="textarea" {...field} id="reviewText" isInvalid={!!errors.reviewText} rows={4} />
                                            <Form.Control.Feedback type="invalid">{errors.reviewText}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <Row className="mb-3">
                                <Field name="reviewFiles">
                                {({ form }) => (
                                    <Table>
                                        <tbody>
                                            {values.reviewFiles.map((reviewFile, index) =>
                                                <tr key={reviewFile.guestReviewFileId}>
                                                    {/*<td><a target="_blank" href={"http://localhost:47702/MyImages/ReviewFiles/" + reviewFile.guestReviewId + "/" + reviewFile.fileName} rel="noopener noreferrer" download>{reviewFile.fileName}</a></td>*/}
                                                    <td className="td-padding-left">
                                                        <a target="_blank" href={DownloadReviewFileByFileName + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} rel="noopener noreferrer" download>{reviewFile.fileName}</a>
                                                        {/*<CloseButton onClick={event => deleteFile(reviewFile, index, form, values)} />*/}
                                                    </td>
                                                    <td className="end-button td-padding-right">
                                                        <i className="fa fa-trash show-cursor-as-pointer" onClick={() => deleteFile(reviewFile, index, form, values)}></i>
                                                    </td>
                                                    {/*<td><a href="http://localhost:47702/MyImages/ReviewFiles/23/mercedes_1.jpg" download> {reviewFile.fileName}</a></td>*/}
                                                    {/*<td><RouteLink to={"/review/update/" + guestReview.guestReviewId}>Edit Review</RouteLink> | <Button onClick={() => this.deleteUser(guestReview.guestReviewId, index)}>Delete Review</Button></td>*/}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                )}
                                </Field>
                            </Row>
                            <Row className="mb-3">
                                <Field name="file">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.files && touched.files} as={Col}>
                                            <Form.Label>Upload Files ( Only the following formats are accepted: .jpeg, .jpg, .png, .pdf and .doc)</Form.Label>

                                            <Form.Control type="file" multiple={true} {...field} id="file" onChange={event => handleFileUploadChange(event, form)} isInvalid={!!errors.files} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.files}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                            </Row>

                            <SaveButton buttonText="Update Review" />                            
                        </Form>
                    )}
                </Formik>
            </div>
            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </div>
    );
}