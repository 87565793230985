import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { ReviewBaseUrl } from "../../services/BaseService";
import { PropertyService } from "../../services/PropertyService";
import { ReviewTagService } from "../../services/ReviewTagService";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "application/pdf","application/msword"];
const maxAllowedFiles = 5;
const maxAllowedFilesMessage = 'Maximum ' + maxAllowedFiles + ' files allowed!';

const schema = yup.object().shape({
    firstName: yup.string().required('Please enter firstname'),
    lastName: yup.string().required('Please enter lastname'),
    address: yup.string().required('Please enter address'),
    zipcode: yup.string().required('Please enter zipcode'),
    countryId: yup.number().required('Please select country'),
    stateId: yup.number().required('Please select state'),
    cityId: yup.number().required('Please select city'),
    drivingLicenseNumber: yup.string().required('Please enter driving license number'),
    email: yup.string().required('Please enter email').email("Enter valid email"),
    mobile: yup.string().required('Please enter mobile'),
    reviewTags: yup.array(),//.required('Please select reviewtag')
    reviewText: yup.string().required('Please enter review'),
    files: yup.array().max(maxAllowedFiles, maxAllowedFilesMessage).test('fileType', "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf and .doc", (value, event) => {
        let IsValid = true;
        if (value) {
            for (var i = 0; i < value.length; i++) {
                if (!SUPPORTED_FORMATS.includes(value[i].type)) {
                    IsValid = false;
                }
            }
        } else {
            IsValid = false;
        }
        return IsValid;
        //return value && SUPPORTED_FORMATS.includes(value[0].type);
    })
});

export default function AddReviewAdv() {

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        zipcode: '',
        countryId: '231',
        stateId: '',
        cityId: '',
        drivingLicenseNumber: '',
        email: '',
        mobile: '',
        reviewTags: '',
        reviewText: '',
        createdByPropertyId: authenticationService.currentUserValue.propertyId,
        reviewByPropertyId: authenticationService.currentUserValue.propertyId,
        reviewByUserId: authenticationService.currentUserValue.userId,
        createdByUserId: authenticationService.currentUserValue.userId,
        file: undefined,
        IsActive: true
    });

    const [options, setOptions] = useState([]);
    const animatedComponents = makeAnimated();
    const [selectedOption, setSelectedOption] = useState([]);

    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const [loading, setLoading] = React.useState(true);
    const [countryCollection, setCountryCollection] = useState([{ label: "Loading ...", value: "" }]);
    const [stateCollection, setStateCollection] = useState([]);
    const [cityCollection, setCityCollection] = useState([]);
    const [fileCollection, setFileCollection] = useState([]);
    const [reviewTagCollection, setReviewTagCollection] = useState([]);    
    let history = useHistory();
    useEffect(() => {
        getCountryList();
        getReviewTagList();

        async function getCountryList() {
            const response = await PropertyService.getCountryList(); //await axios.get('http://localhost:47702/api/Property/GetAllCountry');
            setCountryCollection(response.data.countryCollection.tListResult);
            setLoading(false);

            if (parseInt(data.countryId) > 0) {
                getStateList(data.countryId);
            }
        }

        async function getReviewTagList() {
            const response = await ReviewTagService.getReviewTagList();
            setReviewTagCollection(response.data);

            //debugger;
            let tagOptions = [];
            response.data.forEach((tag) => {
                tagOptions.push({ value: tag.tagName, label: tag.tagName } );
            });
            setOptions(tagOptions);
        }

    }, []);

    const handleCountryChange = (event) => {
        data.stateId = '';
        data.cityId = '';
        setCityCollection([])
        getStateList(event.target.value);

        
    };

    const handleFileUploadChange = (event,form) => {
        setFileCollection(event.target.files);
        let fileNames = [];
        for (var i = 0; i < event.currentTarget.files.length; i++) {
            fileNames.push({ name: event.currentTarget.files[i].name, type: event.currentTarget.files[i].type});
        }
        form.setFieldValue('files', fileNames);
        data.files = fileNames;
    };

    async function getStateList(countryId) {
        const response = await PropertyService.getStateList(countryId); //await axios.get('http://localhost:47702/api/Property/GetStateByCountryId?countryId=' + countryId);
        setStateCollection(response.data.stateCollection.tListResult);
    }

    const handleStateChange = (event) => {
        data.cityId = '';
        getCityList(event.target.value);

        async function getCityList(stateId) {
            const response = await PropertyService.getCityList(stateId);// await axios.get('http://localhost:47702/api/Property/GetCityByStateId?stateId=' + stateId);
            setCityCollection(response.data.cityCollection.tListResult);
        }

    };

    const handleSubmit = (values, actions) => {
        if (data.files!=undefined && data.files.length == 0 && fileCollection.length == 0) {
            Swal.fire({
                title: 'Choose files',
                text: "Choose at least 1 file!",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return;
        }

        //debugger;
        if (selectedOption.length == 0) {
            Swal.fire({
                title: 'Review Tag!',
                text: "Please select reviewtag",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return;
        }

        const formData = new FormData();
        for (var index = 0; index < fileCollection.length; index++) {
            formData.append('file', fileCollection[index]);
        }
        //values.reviewTags = JSON.stringify(values.reviewTags);
        values.reviewTags = JSON.stringify(selectedOption.map(option => option.label));
        //debugger;
        formData.append('Review', JSON.stringify(values));
        const headers= {
                'Content-Type': 'multipart/form-data'
            };

        axios.post(ReviewBaseUrl, formData, { headers: headers }).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success == true) {
                    //setShowHide(true);
                    //setMessage('Record saved successfully!');
                    //setTitle('Success');
                    history.push("/reviews");
                } else {
                    //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
                    setShowHide(true);
                    setMessage('Error while trying to add review');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data && error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    };

    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <BreadcrumbsNav url="/reviews" urlText="Reviews" typographyText="New Review" />

                {/*<div className="row">*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <h6 className="m-0 font-weight-bold text-primary float-start">New Review</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <RouteLink to={"/reviews"} className="float-end">*/}
                {/*            <Button> Review List </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="card-body">
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {/*<div className="row">*/}
                                {/*<div className="col-xs-3 col-sm-2 col-md-2">*/}
                                {/*    <img src="../img/undraw_profile.svg" className="default-profile-image-size rounded mx-auto d-block" alt="profile image" />*/}
                                {/*    <div className="bottom text-center">Upload a picture if available</div>*/}
                                {/*</div>*/}
                                {/*<div className="col-xs-9 col-sm-10 col-md-10">*/}
                                    
                                    <Row className="mb-3">

                                        <Field name="firstName">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control type="text" {...field} id="firstName" isInvalid={!!errors.firstName} autoFocus />
                                                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="lastName">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.lastName && touched.lastName} as={Col}>
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control type="text" {...field} id="lastName" isInvalid={!!errors.lastName} />
                                                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>
                                    <Row className="mb-3">

                                        <Field name="address">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.address && touched.address} as={Col}>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" {...field} id="address" isInvalid={!!errors.address} />
                                                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="zipcode">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.zipcode && touched.zipcode} as={Col}>
                                                    <Form.Label>Zipcode</Form.Label>
                                                    <Form.Control type="text" {...field} id="zipcode" isInvalid={!!errors.zipcode} />
                                                    <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>
                                    <Row className="mb-3">
                            
                                        <Field name="countryId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.countryId && touched.countryId} as={Col} onChange={handleCountryChange}>
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Select {...field} id="countryId" isInvalid={!!errors.countryId} disabled={loading} >

                                                        <option key="0" value="0">Please select country</option>

                                                        {countryCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}

                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.countryId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="stateId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.stateId && touched.stateId} as={Col} onChange={handleStateChange}>
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Select {...field} id="stateId" isInvalid={!!errors.stateId} disabled={loading} >
                                                        <option key="0" value="0">Please select state</option>
                                                        {stateCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>
                                    <Row className="mb-3">

                                        <Field name="cityId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.cityId && touched.cityId} as={Col}>
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Select {...field} id="cityId" isInvalid={!!errors.cityId} disabled={loading} >
                                                        <option key="0" value="0">Please select city</option>
                                                        {cityCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.cityId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="drivingLicenseNumber">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.drivingLicenseNumber && touched.drivingLicenseNumber} as={Col}>
                                                    <Form.Label>Driving license number</Form.Label>
                                                    <Form.Control type="text" {...field} id="drivingLicenseNumber" isInvalid={!!errors.drivingLicenseNumber} />
                                                    <Form.Control.Feedback type="invalid">{errors.drivingLicenseNumber}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">

                                        <Field name="email">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.email && touched.email} as={Col}>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text" {...field} id="email" isInvalid={!!errors.email} />
                                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="mobile">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.mobile && touched.mobile} as={Col}>
                                                    <Form.Label>Mobile</Form.Label>
                                                    <Form.Control type="text" {...field} id="mobile" isInvalid={!!errors.mobile} />
                                                    <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">                          
                                        <Field name="reviewTags">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.reviewTags && touched.reviewTags} as={Col}>
                                                    <Form.Label>Review Tag</Form.Label>
                                                    {/*<Form.Select multiple={true} {...field} id="reviewTags" isInvalid={!!errors.reviewTags}>*/}
                                                    {/*    {reviewTagCollection.map((item, i) => {*/}
                                                    {/*        return (<option key={i} value={item.tagName}>{item.tagName}</option>)*/}
                                                    {/*    })}*/}
                                                    {/*</Form.Select>*/}
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        options={options}
                                                        id="reviewTags"
                                                        value={selectedOption}
                                                        onChange={setSelectedOption} />
                                                    <Form.Control.Feedback type="invalid">{errors.reviewTags}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                        <Field name="reviewText">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.reviewText && touched.reviewText} as={Col}>
                                                    <Form.Label>Review</Form.Label>
                                                    <Form.Control as="textarea" {...field} id="reviewText" isInvalid={!!errors.reviewText} rows={4}/>
                                                    <Form.Control.Feedback type="invalid">{errors.reviewText}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">
                                        <Field name="file">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.files && touched.files} as={Col}>
                                                    <Form.Label>Upload Files ( Only the following formats are accepted: .jpeg, .jpg, .png, .pdf and .doc)</Form.Label>

                                                    <Form.Control type="file" multiple={true} {...field} id="file" onChange={event => handleFileUploadChange(event, form)} isInvalid={!!errors.files}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.files}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                            
                                    </Row>
                                    <SaveButton buttonText="Save Review" />
                                    
                                {/*</div>*/}
                            {/*</div>*/}
                        </Form>
                    )}
                </Formik>
            </div>
            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </div>
    );
}