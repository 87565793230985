import axios from "axios";
import React, { Component } from "react";
import { Link as RouteLink } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import CommonTableHeader from "../../components/CommonUIs";
import DataTableCustomPagewise from "../../components/DataTableCustomPagewise";
import { authenticationService } from "../../services/AuthenticationService";
import { DeletePropertyById, GetAllPropertyPagewise, PropertyBaseUrl } from "../../services/BaseService";

export default class PropertyList extends Component {

    constructor(props) {
        super(props);
        
        const columnsHeaders = [
            {
                name: 'Property Name',
                selector: row => row.propertyName,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Address',
                selector: row => row.address,
                sortable: true,
            },
            {
                name: 'Country',
                selector: row => row.countryName,
                sortable: true,
            },
            {
                name: 'State',
                selector: row => row.stateName,
                sortable: true,
            },
            {
                name: 'City',
                selector: row => row.cityName,
                sortable: true,
            },
            {
                name: 'Zip',
                selector: row => row.zip,
                sortable: true,
            },
            {
                name: 'Phone',
                selector: row => row.phone,
                sortable: true,
            },
            {
                name: 'Allowed Manager',
                selector: row => row.allowedManager,
                sortable: true,
                center: true,
            },
            {
                name: 'Allowed Staff',
                selector: row => row.allowedStaff,
                sortable: true,
                center: true,
            },
            {
                name: 'Website',
                selector: row => row.website,
                sortable: true,
            },
            {
                name: '',
                ignoreRowClick: true,
                button: true,
                cell: (row: any) => <>
                    <RouteLink to={"/property/update/" + (row.propertyId)} className="icon-right-margin"><i className="fas fa-pencil-alt"></i></RouteLink>
                    <i className="fa fa-trash show-cursor-as-pointer" onClick={() => this.deleteProperty(row)}></i>
                                    </>,
            }
        ];

        const excludeFromFilter = ['PropertyId', 'CityId', 'StateId', 'CountryId', 'CreatedDateTime', 'CreatedByUserId'];

        const isIndeterminate = (indeterminate) => indeterminate;

        this.state = {
            properties: [],
            columns: columnsHeaders,
            excludeFromFilter: excludeFromFilter,
            selectableRowsComponentProps: { indeterminate: isIndeterminate },
            loading: true,
            show: false,
            message: '',
            title: 'Success',
            totalRows: 0,
            perPage: 10,
        };
       
        //this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.getListPagewise = this.getPropertyListPagewise.bind(this);
    }

    componentDidMount() {
        //this.getPropertyList();
        this.getPropertyListPagewise(1, this.state.perPage);
    }

    refreshPage = () => {
        window.location.reload();
    }

    deleteProperty = (property) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(DeletePropertyById + property.propertyId).then(
                (result) => {
                    //debugger;
                    if (result.data.responseInfo.success == true) {
                        //this.setState({ loading: true });
                        //this.props.history.push("/property");
                        const indexOfItem = this.state.properties.indexOf(property);

                        this.state.properties.splice(indexOfItem, 1);
                        this.setState({ properties: this.state.properties }); //, loading: false });
                        if (this.state.properties.length == 0) {
                            var user = authenticationService.currentUserValue;
                            user.propertyId = 0;
                            authenticationService.setUser(user);
                            //this.refreshPage();
                        } else if (authenticationService.currentUserValue.propertyId == property.propertyId) {
                            var user = authenticationService.currentUserValue;
                            user.propertyId = this.state.properties[0].propertyId;
                            authenticationService.setUser(user);
                            //this.refreshPage();
                        }
                    } else {
                        this.setState({ show: true, message: 'Error while deleting property', title: 'Error' });
                    }
                },
                (error) => {
                    if (error.response.status === 400) {
                        if (error.response.data.errors) {
                            var message = '';
                            Object.entries(error.response.data.errors).map(([key, value]) => {
                                message += value + '<br/>';
                                return value;
                            });
                            this.setState({ show: true, message: message, title: 'Error' });
                        }
                    }
                });
        }
    }

    renderPropertyTable(properties) {
        return (

            <div className="card shadow mb-4">
                <CommonTableHeader url="/property/add" urlText="New Property" title="Properties" />
                <DataTableCustomPagewise
                    columns={this.state.columns}
                    data={properties}
                    excludeFromFilter={this.state.excludeFromFilter}
                    totalRows={this.state.totalRows}
                    perPage={this.state.perPage}
                    getListPagewise={this.getListPagewise}
                />
            </div>

        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPropertyTable(this.state.properties);

        return (
            <>
                {contents}

                <SweetAlert
                    show={this.state.show}
                    title={this.state.title}
                    html={this.state.message}
                    onConfirm={() => this.setState({ show: false })} />
            </>
        );
    }

    async getPropertyList() {

        // // const response = await axios.get('http://localhost:47702/api/Property?propertyId=' + authenticationService.currentUserValue.propertyId);

        const response = await axios.get(PropertyBaseUrl);
        const data = await response.data;
        this.setState({ properties: data != '' ? data : [], loading: false });
    }

    async getPropertyListPagewise(page, perPage) {
        const response = await axios.get(GetAllPropertyPagewise + "&page=" + page + "&perPage=" + perPage);
        const data = await response.data;
        //debugger;
        this.setState({ properties: data.tListResult != '' ? data.tListResult : [], loading: false });
        if (page == 1) {
            this.setState({ totalRows: data.totalRecords });

            //if (authenticationService.currentUserValue.propertyId == 0 && this.state.properties.length == 1) {
            //    var user = authenticationService.currentUserValue;
            //    user.propertyId = this.state.properties[0].propertyId;
            //    debugger;
            //    authenticationService.setUser(user);
            //    this.refreshPage();
            //}
        }
    }
}