
import SortIcon from "@mui/icons-material/ArrowDownward";
import React from 'react';
import { useState } from "react";
import DataTable from 'react-data-table-component';
import styled from 'styled-components';


const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #7e7e7e;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const customStyles = {
	//rows: {
	//	style: {
	//		minHeight: '72px', // override the row height
	//	},
	//},
	headCells: {
		style: {
			//paddingLeft: '8px', // override the cell padding for head cells
			//paddingRight: '8px',
			fontWeight: 'bold',
			fontSize:'14px',			
		},
	},
	cells: {
		style: {
			fontSize: '14px',
		},
	},
	pagination: {
		style: {
			color: '#0e0707c9',
		},
	},	
};

//const ClearButton = styled(Button)`
//	border-top-left-radius: 0;
//	border-bottom-left-radius: 0;
//	border-top-right-radius: 5px;
//	border-bottom-right-radius: 5px;
//	height: 34px;
//	width: 32px;
//	text-align: center;
//	display: flex;
//	align-items: center;
//	justify-content: center;
//`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
			autoFocus
		/>
	</>
);

//<ClearButton type="button" onClick={onClear}>
//	X
//</ClearButton>

const getValues = (item, excludeFromFilter) => {
	var valuesToFilter = [];
	excludeFromFilter = excludeFromFilter.map(columnName => columnName.toLowerCase());

	for (const [key, value] of Object.entries(item)) {
		//console.log(`${key}: ${value}`);
		if (!excludeFromFilter.includes(key.toLowerCase())) {
			//debugger;
			valuesToFilter.push(value +"");
        }
	}

	return valuesToFilter;
};

export default function DataTableCustomPagewise(props) {
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filterField = (search, value) => value.toLowerCase().indexOf(search.toLowerCase()) >= 0;
	const orFilter = (search, values) => values.some(filterField.bind(null, search));
	const filteredItems = props.data.filter(item => 
		orFilter(filterText, getValues(item, props.excludeFromFilter))
	);
	//  [item.propertyName, item.phone, item.zip]

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	const [perPage, setPerPage] = useState(props.perPage === undefined ? 10 : props.perPage);

	const handlePerRowsChange = (newPerPage, page) => {
		//setLoading(true);
		//debugger;
		//console.log("newPerPage => " + newPerPage);
		setPerPage(newPerPage);
		props.getListPagewise(page, newPerPage);
		//this.setState({ perPage: newPerPage });

		//setLoading(false);
	};

	const handlePageChange = (page) => {
		//setLoading(true);
		//debugger;
		//console.log("this.state.perPage => " + this.state.perPage);
		props.getListPagewise(page, perPage);
	};

	return (
        <div className="card-body">
			<DataTable
				columns={props.columns}
				data={filteredItems}
				sortIcon={<SortIcon />}
				pagination
				noDataComponent="Records not found."
				paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				customStyles={customStyles}
				paginationServer
				paginationTotalRows = { props.totalRows }
				onChangeRowsPerPage = { handlePerRowsChange }
				onChangePage={handlePageChange }
            />
        </div>
	);

	
    {/*title="Properties"*/ }
    {/*selectableRows*/ }
    {/*selectableRowsComponent={Checkbox}*/ }
    {/*selectableRowsComponentProps={this.state.selectableRowsComponentProps}*/ }

    {/*theme="dark"*/ }
    {/*defaultSortField="propertyName"*/ }
}