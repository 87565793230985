import * as React from 'react';
import { Route, RouteProps, Redirect } from "react-router-dom";
import { authenticationService } from '../services/AuthenticationService';
//import { useSelector } from "react-redux";

interface Props extends RouteProps { }

export const PublicRoute: React.FC<Props> = props => {
    const isAuthenticated: boolean = authenticationService.isAuthenticated(); // useSelector<any, any>((stores) => stores.auth.isAuthenticated);
    const { component: Component, ...restProps } = props;

    if (!Component) return null;

    return (
        <Route
            {...restProps}
            render={routeRenderProps => (
                !isAuthenticated || props.skipAuth==true ? (
                    <Component {...routeRenderProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: { from: routeRenderProps.location }
                        }}
                    />
                )
            )}
        />
    )
}