import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { DeleteReviewFileById, GetReviewById, GetReviewFilesByReviewId } from './BaseService';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const ReviewService = {
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    isAuthenticated: function () {
        if (currentUserSubject.value && currentUserSubject.value != null)
            return true;
        else
            return false;
    },
    //APIURL: {
    //    reviewGet: 'http://localhost:47702/api/' + 'Review/GetById',
    //    countryGet: 'http://localhost:47702/api/' + 'Property/GetAllCountry',
    //    stateGetByCountryId: 'http://localhost:47702/api/' + 'Property/GetStateByCountryId?countryId=',
    //    cityGetByStateId: 'http://localhost:47702/api/' + 'Property/GetCityByStateId?stateId=',
    //    deleteReviewFile: 'http://localhost:47702/api/Review/DeleteReviewFile/',
    //},
    get Role() { return currentUserSubject.value != null ? currentUserSubject.value.roles[0] : '' },    
    getReviewData,
    //getCountryList,
    //getStateList,
    //getCityList,
    getReviewFilesList,
    deleteGuestReviewFile
}

async function getReviewData(ReviewId) {    
    return await axios.get(GetReviewById + ReviewId);//(this.APIURL.reviewGet, ReviewId); //axios.post(this.APIURL.loginPost, loginRequest);
}

//async function getCountryList() {
//    return await axios.get(GetCountry);
//}

//async function getStateList(countryId) {
//    return await axios.get(GetStateByCountryId + countryId);
//}
//async function getCityList(stateId) {
//    return await axios.get(GetCityByStateId + stateId); 
//}

async function getReviewFilesList(guestReviewId) {
    return await axios.get(GetReviewFilesByReviewId + guestReviewId);
}

async function deleteGuestReviewFile(guestReviewFileId) {
    return await axios.delete(DeleteReviewFileById + guestReviewFileId);
}