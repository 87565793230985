import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { Component } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { ReviewTagBaseUrl, ReviewTagById } from "../../services/BaseService";
const schema = yup.object().shape({
    tagName: yup.string().required('Please enter tag name')
    
});

export class EditReviewTag extends Component {
    constructor(props) {
        super(props);

        let defaultReviewTag = {
            tagName: '',
        };

        const { id } = this.props.match.params;
        this.state = { reviewTag: defaultReviewTag, isValueLoaded: false, show: false, message: '', title: 'Success', tagMasterId: id };
    }

    componentDidMount() {
        this.getReviewTagId();
    }

    getReviewTagId = async () => {
        //debugger;
        const response = await axios.get(ReviewTagById + this.state.tagMasterId);
        const data = await response.data.reviewTag.tResult;
        if (data && data.tagMasterId > 0) {
            this.setState({ reviewTag: data, isValueLoaded: true });
        }
        else {
            this.props.history.push("/reviewtag");
        }
    }

    handleSubmit = (values, actions) => {
        
        axios.put(ReviewTagBaseUrl, values).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success == true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    this.props.history.push("/reviewtag");
                } else {
                    this.setState({ show: true, message: 'Error while updating review tag', title: 'Error' });
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        this.setState({ show: true, message: message, title: 'Error' });
                    }
                }
                actions.setSubmitting(false);
            });
    };
    render() {
        return !this.state.isValueLoaded
            ? <p><em>Loading...</em></p>
            : (
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <BreadcrumbsNav url="/reviewtag" urlText="Review Tags" typographyText="Update Review Tag" />

                        {/*<div className="row">*/}
                        {/*    <div className="col align-self-center">*/}
                        {/*        <h6 className="m-0 font-weight-bold text-primary float-start">Update Review Tag</h6>*/}
                        {/*    </div>*/}
                        {/*    <div className="col align-self-center">*/}
                        {/*        <RouteLink to={"/reviewtag"} className="float-end">*/}
                        {/*            <Button> Review Tag List </Button>*/}
                        {/*        </RouteLink>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="card-body">
                        <Formik
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                            initialValues={this.state.reviewTag}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                actions
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row className="mb-3">

                                        <Field name="tagName">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.tagName && touched.tagName} as={Col}>
                                                    <Form.Label>Tag name</Form.Label>
                                                    <Form.Control type="text" {...field} id="tagName" isInvalid={!!errors.tagName} autoFocus/>
                                                    <Form.Control.Feedback type="invalid">{errors.tagName}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>
                                    <SaveButton buttonText="Update Review Tag" />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        title={this.state.title}
                        html={this.state.message}
                        onConfirm={() => this.setState({ show: false })} />
                </div>
            );
    }
}