import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { GetDashboardData } from './BaseService';

//const currentUserSubject = new BehaviorSubject({ username: 'Vijay', roles: ['Admin'], userId: 2, propertyId: 4 });
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const HomeService = {
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    isAuthenticated: function () {
        if (currentUserSubject.value && currentUserSubject.value != null)
            return true;
        else
            return false;
    },
    get Role() { return currentUserSubject.value != null ? currentUserSubject.value.roles[0] : '' },
    
    getDashboardData,
}

async function getDashboardData(userRole, propertyId) {
    return await axios.get(GetDashboardData + userRole + "&propertyId=" + propertyId);
}

