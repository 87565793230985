import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
//axios.defaults.withCredentials = true;
// For GET requests
axios.interceptors.request.use(
    (req) => {
        // Add configurations here
        var contentType = 'application/json';
        if (req.headers['Content-Type']) {
            contentType = req.headers['Content-Type'];
        }
        req.headers = { 'Content-Type': contentType, 'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS', 'Access-Control-Allow-Headers': '*', 'Access-Control-Allow-Credentials': true, 'Authorization': 'Bearer ' + localStorage.getItem('sToken') };


        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

// For POST requests
axios.interceptors.response.use(
    (res) => {
        // Add configurations here
        if (res.status === 201) {
            console.log('Posted Successfully');
        }
        else if (res.status === 403) {
            console.log('Forbidden');
        }
        else if (res.status === 221) {

        }
        return res;
    },
    (err) => {
        if (err.response.status === 403) {
            console.log('Forbidden');
        }
        return Promise.reject(err);
    }
);
ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

