import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { ReviewTagBaseUrl } from "../../services/BaseService";

const schema = yup.object().shape({
    tagName: yup.string().required('Please enter tag name')
});

export default function AddReviewTag() {
    const [data, setData] = useState({
        tagName: '',
    });

    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    let history = useHistory();
    const handleSubmit = (values, actions) => {
        //debugger;
        axios.post(ReviewTagBaseUrl, values).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.tagMaster.success == true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    history.push("/reviewtag");
                } else {
                    //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
                    setShowHide(true);
                    setMessage('Error while trying to add review tag');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    };
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <BreadcrumbsNav url="/reviewtag" urlText="Review Tags" typographyText="New Review Tag" />

                {/*<div className="row">*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <h6 className="m-0 font-weight-bold text-primary float-start">New Review Tag</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <RouteLink to={"/reviewtag"} className="float-end">*/}
                {/*            <Button> Review Tag List </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="card-body">
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">

                                <Field name="tagName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.tagName && touched.tagName} as={Col}>
                                            <Form.Label>Tag name</Form.Label>
                                            <Form.Control type="text" {...field} id="tagName" isInvalid={!!errors.tagName} autoFocus/>
                                            <Form.Control.Feedback type="invalid">{errors.tagName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <SaveButton buttonText="Save Review Tag" />
                        </Form>
                    )}
                </Formik>
            </div>
            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </div>
    );
}