import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps
} from "react-router-dom";
import { authenticationService } from '../services/AuthenticationService';
//import { useSelector } from "react-redux";

interface Props extends RouteProps { }

export const PrivateRoute: React.FC<Props> = props => {
    const isAuthenticated: boolean = authenticationService.isAuthenticated(); //useSelector<any, any>((stores) => stores.auth.isAuthenticated);
    const { component: Component, ...restProps } = props;

    if (!Component) return null;

    return (
        <Route
            {...restProps}
            render={routeRenderProps =>
                isAuthenticated ? props.skipAuth==true || authenticationService.checkPassedRolesMatched(props.roles) ? (
                    <Component {...routeRenderProps} addCurrentLink={props.addCurrentLink} currentLink={props.currentLink}/>
                ) : (<Redirect
                    to={{
                        pathname: "/forbidden",
                        state: { from: routeRenderProps.location }
                    }}
                />) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: routeRenderProps.location }
                        }}
                    />
                )
            }
        />
    )
}