
import React from 'react';
import { Button} from "react-bootstrap";
import { Link as RouteLink } from 'react-router-dom';

export default function CommonTableHeader(props) {
    
    return (
        <div className="card-header table-header-padding">
            <div className="row">
                <div className="col center-box">
                    <h5 className="m-0 text-gray-800 table-header-title-text float-start center-box ">{props.title}</h5>
                    {/*<h6 className="m-0 font-weight-bold text-primary float-start center-box ">Properties</h6>*/}
                </div>
                <div className={props.url == '' ? "col display-button-none" : "col display-button-block"}>
                    <RouteLink to={props.url} className="float-end">
                        <Button> {props.urlText} </Button>
                    </RouteLink>
                </div>
            </div>
        </div>
    );
}