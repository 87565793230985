import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { Component } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { UserBaseUrl, UserByIdUrl } from "../../services/BaseService";

const schema = yup.object().shape({
    email: yup.string().required('Please enter email'),
    password: yup.string().required('Please enter password')
        .max(20, "Password must be of max 20 characters long")
        .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"),
    username: yup.string().required('Please enter username'),
    firstName: yup.string().required('Please enter firstname'),
    lastName: yup.string().required('Please enter lastname'),
    mobile: yup.string().required('Please enter mobile number'),
    userRole: yup.string().required('Please select user role')
});

export class EditUser extends Component {
    constructor(props) {
        super(props);

        let defaultUser = {
            email: '',
            password: '',
            username: '',
            firstName: '',
            lastName: '',
            mobile: '',
            userRole: '',
            userId:'',
            propertyId: authenticationService.currentUserValue.propertyId,
            createdbyuserid: authenticationService.currentUserValue.userId
        };

        props.addCurrentLink(window.location.pathname);
        const { id } = this.props.match.params;
        this.state = {
            user: defaultUser, isValueLoaded: false, show: false, message: '', title: 'Success', userId: id,
            userRoleCollection: [{ "id": "2", "rolename": "Owner" }, { "id": "3", "rolename": "Manager" }, { "id": "4", "rolename": "Staff" }]  
        };
    }
    componentDidMount() {
        this.getUserById();
    }
    getUserById = async () => {
        //debugger;
        const response = await axios.get(UserByIdUrl + this.state.userId);
        const data = await response.data;
        if (data && data.userId > 0) {
            data.propertyId = authenticationService.currentUserValue.propertyId;
            this.setState({ user: data, isValueLoaded: true });
        }
        else {
            this.props.history.push("/users");
        }
    }

    handleSubmit = (values, actions) => {
        //debugger;
        axios.put(UserBaseUrl, values).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success == true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    this.props.history.push("/users");
                } else {
                    this.setState({ show: true, message: result.data.responseInfo.message, title: 'Error' });
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        this.setState({ show: true, message: message, title: 'Error' });
                    }
                }
                actions.setSubmitting(false);
            });
    };
    render() {
        return !this.state.isValueLoaded
            ? <p><em>Loading...</em></p>
            : (
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <BreadcrumbsNav url="/users" urlText="Users" typographyText="Update User" />

                    {/*<div className="row">*/}
                    {/*    <div className="col align-self-center">*/}
                    {/*        <h6 className="m-0 font-weight-bold text-primary float-start">Update User</h6>*/}
                    {/*    </div>*/}
                    {/*    <div className="col align-self-center">*/}
                    {/*        <RouteLink to={"/users"} className="float-end">*/}
                    {/*            <Button> User List </Button>*/}
                    {/*        </RouteLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className="card-body">
                    <Formik
                        validationSchema={schema}
                        onSubmit={this.handleSubmit}
                        initialValues={this.state.user}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        actions
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">

                                <Field name="firstName">
                                    {({ field, form }) => (
                                            <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                            <Form.Label>First name</Form.Label>
                                                <Form.Control type="text" {...field} id="firstName" isInvalid={!!errors.firstName} autoFocus/>
                                                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="lastName">
                                    {({ field, form }) => (
                                            <Form.Group isInvalid={errors.lastName && touched.lastName} as={Col}>
                                            <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text" {...field} id="lastName" isInvalid={!!errors.lastName} />
                                                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">

                                <Field name="email">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.email && touched.email} as={Col}>
                                            <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" {...field} id="email" isInvalid={!!errors.email} readOnly />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="mobile">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.mobile && touched.mobile} as={Col}>
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control type="text" {...field} id="mobile" isInvalid={!!errors.mobile} />
                                            <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                                    <Field name="username">
                                    {({ field, form }) => (
                                            <Form.Group isInvalid={errors.username && touched.username} as={Col}>
                                            <Form.Label>User name</Form.Label>
                                                <Form.Control type="text" {...field} id="username" isInvalid={!!errors.username} readOnly />
                                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="password">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.password && touched.password} as={Col}>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" {...field} id="password" isInvalid={!!errors.password} />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                                <Field name="userRole">
                                    {({ field, form }) => (
                                            <Form.Group isInvalid={errors.userRole && touched.userRole} as={Col}>
                                            <Form.Label>User Role</Form.Label>
                                                <Form.Select {...field} id="userrole" isInvalid={!!errors.userRole}>
                                                <option key="0" value="">Please select user role</option>
                                                {this.state.userRoleCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.rolename}</option>
                                                    )
                                                })}

                                            </Form.Select>

                                                <Form.Control.Feedback type="invalid">{errors.userRole}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field >
                                    {({ field, form }) => (
                                        <Form.Group as={Col}>

                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                            <SaveButton buttonText="Update User" />
                        </Form>
                    )}
                </Formik>
                </div>
                    <SweetAlert
                        show={this.state.show}
                        title={this.state.title}
                        html={this.state.message}
                        onConfirm={() => this.setState({ show: false })} />
            </div>
        );
    }
}