import { Field, Formik } from 'formik';
import React, { useState } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { authenticationService } from '../../services/AuthenticationService';
import SweetAlert from 'sweetalert2-react';

const schema = yup.object().shape({
    username: yup.string().required('Please enter username'),
    password: yup.string().required('Please enter password'),
});

export default function Login() {

    const [data, setData] = useState({
        username: '',
        password: '',
    });

    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const handleSubmit = (values, actions) => {
        
        authenticationService.login({ Username: values.username, Password: values.password }).then(
            (result) => {
                actions.setSubmitting(false);
                var res = result.data;
                if (res.response && res.response.success == true) {
                    const dataToken = res.response.message;
                    console.log(dataToken);
                    localStorage.setItem('sToken', dataToken);
                    authenticationService.setUser(res.response.tResult);
                    //debugger;
                    //history.push("/home");
                    // not using history.push() here because jquery was not loading for /home using history.push()
                    const currentUrl = window.location.href;
                    window.location = currentUrl.substring(0, currentUrl.indexOf('/login')) + "/home";
                } else {
                    setShowHide(true);
                    setMessage(res.response.message);
                    setTitle('Login failed!');
                }
                //alert(res.response.message);
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    };
    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={data}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="user">
                        <Row>
                            <Field name="username">
                                {({ field, form }) => (
                                    <Form.Group isInvalid={errors.username && touched.username} as={Col} className="mb-3">
                                        <Form.Control type="text" {...field} id="username" isInvalid={!!errors.username} autoFocus className="form-control form-control-user" placeholder="Username" />
                                        {/*<Form.Text className="text-muted">*/}
                                        {/*    We'll never share your email with anyone else.*/}
                                        {/*</Form.Text>*/}
                                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                        </Row>
                        <Row>
                            <Field name="password">
                                {({ field, form }) => (
                                    <Form.Group isInvalid={errors.password && touched.password} as={Col} className="mb-3">
                                        <Form.Control type="password" {...field} id="password" isInvalid={!!errors.password} className="form-control form-control-user" placeholder="Password" />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                </div>
                            </Form.Group>
                        </Row>
                        <Button variant="primary" type="submit" className="btn btn-primary btn-user btn-block">
                            Login
                        </Button>
                    </Form>
                )}
            </Formik>

            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </>
    );
}