import React from 'react';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//function SampleNextArrow(props) {
//    const { className, style, onClick } = props;
//    return (
//        <div
//            className={className}
//            style={{ ...style, display: "block"}}
//            onClick={onClick}
//        />
//    );
//}

//function SamplePrevArrow(props) {
//    const { className, style, onClick } = props;
//    return (
//        <div
//            className={className}
//            style={{ ...style, display: "block" }}
//            onClick={onClick}
//        />
//    );
//}

export default function ImageSliderCustom(props) {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        lazyLoad: 'ondemand',

        //nextArrow: <SampleNextArrow />,
        //prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        
        <Slider {...settings}>
            {props.files.map((reviewFile, index) =>
                <a key={reviewFile.guestReviewFileId} target="_blank" href={props.downloadReviewFilePath + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} rel="noopener noreferrer" download>
                    <img className="rounded-corner-image" src={props.downloadReviewFilePath + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} />
                </a>
            )}
        </Slider>
       
    );
}