import * as React from 'react';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
//import { useEffect} from "react";

//function useCurrentNavLink() {
//    const [currentLink, setCurrentLink] = React.useState('');

//    useEffect(() => {
//        //const currenthost = window.location.host;
//        //const currentUrl = window.location.href;
//        const currentLink = window.location.pathname == '/home' ? '' : window.location.pathname;
//        debugger;
//        setCurrentLink(currentLink);
//    }, []);

//    function addCurrentLink(link) {
//        //postEntry(entry);
//        //setCurrentLink(prev => [...prev, entry]);
//        setCurrentLink(link);
//    }

//    return { currentLink, addCurrentLink };
//}

export const LayoutAuthenticated: React.FC<{}> = props => {
    /*const { currentLink, addCurrentLink } = useCurrentNavLink();*/
    
    return (
        <>
            <div id="wrapper">
                <SideNav addCurrentLink={props.addCurrentLink}/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopNav currentLink={props.currentLink}/>
                        <div className="container-fluid">
                            {props.children}
                        </div>
                    </div >
                    {/*<footer className="sticky-footer bg-white">*/}
                    {/*    <div className="container my-auto">*/}
                    {/*        <div className="copyright text-center my-auto">*/}
                    {/*            <span>Copyright &copy; Guest Review System 2021</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</footer>*/}
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>

            
        </>
        /*<TopNavMenu p={props} />*/
        //<div>
        //    <TopNavMenu />
        //    <div className="mt-3 pb-3">
        //        {props.children}
        //    </div>
        //</div>
    )
}