export const Base_ApiUrl = process.env.REACT_APP_API_CALL_URL;

export const Login_API = Base_ApiUrl + "account"
export const Logout_API = Base_ApiUrl + "logout"
export const Forgot_Password_API = Base_ApiUrl + "forgotpassword"
export const GetDashboardData = Base_ApiUrl + "Home/GetDashboardData?userRole="

export const GetCountry = Base_ApiUrl + "Property/GetAllCountry"
export const GetStateByCountryId = Base_ApiUrl + "Property/GetStateByCountryId?countryId="
export const GetCityByStateId = Base_ApiUrl + "Property/GetCityByStateId?stateId="

export const GetReviewById = Base_ApiUrl + "Review/GetById?reviewId="
export const GetReviewFilesByReviewId = Base_ApiUrl + "Review/GetReviewFilesByReviewId?guestReviewId="
export const DeleteReviewFileById = Base_ApiUrl + "Review/DeleteReviewFile?guestReviewFileId="
export const ReviewBaseUrl = Base_ApiUrl + "Review"
export const DeleteReviewById = Base_ApiUrl + "Review?guestReviewId="
export const DownloadReviewFileByFileName = Base_ApiUrl + "Review/Download?fileName="
export const ReviewByPropertyId = Base_ApiUrl + "Review?PropertyId="
export const ReviewByPropertyIdPagewise = Base_ApiUrl + "Review/GetAllReviewByPropertyIdPagewise?PropertyId="
export const ReviewBySearhCriteria = Base_ApiUrl + "Review/GetReviewsByGuestFilter"
export const ReviewBySearhCriteriaPagewise = Base_ApiUrl + "Review/GetReviewsByGuestFilterPagewise"

export const ReviewTagBaseUrl = Base_ApiUrl + "ReviewTag"
export const DeleteReviewTagById = Base_ApiUrl + "ReviewTag?tagMasterId="
export const ReviewTagById = Base_ApiUrl + "ReviewTag/GetById?tagMasterId="
export const ReviewTagsPagewise = Base_ApiUrl + "ReviewTag/GetAllTagsPagewise?page="

export const GetAllProperty = Base_ApiUrl + "Property/GetAllForDropdown"
export const GetAllPropertyPagewise = Base_ApiUrl + "Property/GetAllPropertyPagewise?"
export const PropertyBaseUrl = Base_ApiUrl + "Property"
export const GetPropertyById = Base_ApiUrl + "Property/GetById?propertyId="
export const DeletePropertyById = Base_ApiUrl + "Property?propertyId="

export const UserBaseUrl = Base_ApiUrl + "User"
export const DeleteUserById = Base_ApiUrl + "User?userId="
export const UserByIdUrl = Base_ApiUrl + "User/GetById?userId="
export const UserByPropertyIdUrl = Base_ApiUrl + "User?propertyId="
export const UserByPropertyIdPagewise = Base_ApiUrl + "User/GetAllByPropertyIdPagewise?propertyId="


export const NavDashboard = '/home'
export const NavProperties = '/property'
export const NavUsers = '/users'
export const NavReviews = '/reviews'
export const NavSearchReview = '/review/search'
export const NavReviewTags = '/reviewtag'

