import axios from "axios";
import React, { Component } from "react";
import { Link as RouteLink } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import CommonTableHeader from "../../components/CommonUIs";
import DataTableCustomPagewise from "../../components/DataTableCustomPagewise";
import { authenticationService } from "../../services/AuthenticationService";
import { DeleteReviewById, ReviewByPropertyId, ReviewByPropertyIdPagewise } from "../../services/BaseService";

export default class ReviewList extends Component {

    constructor(props) {
        super(props);

        const columnsHeaders = [
            {
                name: 'First Name',
                selector: row => row.firstName,
                sortable: true,
            },
            {
                name: 'Last Name',
                selector: row => row.lastName,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Review',
                selector: row => row.reviewText,
                sortable: true,
                wrap: true,
            },
            {
                name: '',
                ignoreRowClick: true,
                button: true,
                cell: (row: any) => <>
                    <RouteLink to={"/review/update/" + (row.guestReviewId)} className="icon-right-margin"><i className="fas fa-pencil-alt"></i></RouteLink>
                    <i className="fa fa-trash show-cursor-as-pointer" onClick={() => this.deleteReview(row)}></i>
                </>,
            }
        ];

        const excludeFromFilter = ['guestReviewId', 'GuestId', 'CityId', 'DrivingLicenseNumber', 'Mobile', 'StateId', 'CreatedDateTime', 'CountryId', 'CreatedByUserId', 'CreatedByPropertyId', 'City', 'State', 'Country', 'ReviewTags', 'ReviewByUserId', 'ReviewByPropertyId', 'IsActive'];
        props.addCurrentLink(window.location.pathname);
        this.state = {
            reviews: [],
            columns: columnsHeaders,
            excludeFromFilter: excludeFromFilter,
            loading: true,
            show: false,
            message: '',
            title: 'Success',
            totalRows: 0,
            perPage: 10,
        };
        
        //this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.getListPagewise = this.getReviewListPagewise.bind(this);
    }

    componentDidMount() {
        //this.getReviewList();
        this.getReviewListPagewise(1, this.state.perPage);
    }

    //deleteReview = (userId, indexOfItem) => {
    deleteReview = (review) => {

        if (window.confirm('Are you sure to delete this record?')) {
            //axios.delete(ReviewBaseUrl, { data: review }).then(
            axios.delete(DeleteReviewById + review.guestReviewId + "&guestId=" + review.guestId).then(
                (result) => {
                    
                    if (result.data.success == true) {
                        //this.props.history.push("/users");
                        //this.setState({ loading: true });
                        const indexOfItem = this.state.reviews.indexOf(review);
                        this.state.reviews.splice(indexOfItem, 1);
                        this.setState({ users: this.state.reviews }); //, loading: false });
                    } else {
                        this.setState({ show: true, message: 'Error while deleting user', title: 'Error' });
                    }
                },
                (error) => {
                    if (error.response.status === 400) {
                        if (error.response.data.errors) {
                            var message = '';
                            Object.entries(error.response.data.errors).map(([key, value]) => {
                                message += value + '<br/>';
                                return value;
                            });
                            this.setState({ show: true, message: message, title: 'Error' });
                        }
                    }
                });
        }
    }

    renderUserRole(userRole) {
        switch (userRole) {
            case 2:
                return 'Owner';
            case 3:
                return 'Manager';
            case 4:
                return 'Staff';
            default:
                return 'None';
        }
    }

    renderReviewsTable(reviews) {        
        return (
            <div className="card shadow mb-4">
                <CommonTableHeader url={authenticationService.currentUserValue.propertyId == 0 ? "" : "/review/add"} urlText="New Review" title="Reviews" />
                <DataTableCustomPagewise
                    columns={this.state.columns}
                    data={reviews}
                    excludeFromFilter={this.state.excludeFromFilter}
                    totalRows={this.state.totalRows}
                    perPage={this.state.perPage}
                    getListPagewise={this.getListPagewise}
                />
            </div>

        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReviewsTable(this.state.reviews);

        return (
            <>
                {contents}
                <SweetAlert
                    show={this.state.show}
                    title={this.state.title}
                    html={this.state.message}
                    onConfirm={() => this.setState({ show: false })} />
            </>
        );
    }

    async getReviewList() {
        const response = await axios.get(ReviewByPropertyId + authenticationService.currentUserValue.propertyId);
        const data = await response.data;
        this.setState({ reviews: data != '' ? data.tListResult : [], loading: false });
    }

    async getReviewListPagewise(page, perPage) {
        const response = await axios.get(ReviewByPropertyIdPagewise + authenticationService.currentUserValue.propertyId + "&page="+ page + "&perPage=" + perPage);
        const data = await response.data;
        this.setState({ reviews: data.tListResult != '' ? data.tListResult : [], loading: false });
        if (page == 1) {
            this.setState({ totalRows: data.totalRecords });
        }
    }
}