import axios from "axios";
import React, { Component } from "react";
import { Link as RouteLink } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import CommonTableHeader from "../../components/CommonUIs";
import DataTableCustomPagewise from "../../components/DataTableCustomPagewise";
import { DeleteReviewTagById } from "../../services/BaseService";
import { ReviewTagService } from "../../services/ReviewTagService";

export default class ReviewTagList extends Component {
    constructor(props) {
        super(props);

        const columnsHeaders = [
            {
                name: 'Tag Name',
                selector: row => row.tagName,
                sortable: true,
            },
            {
                name: '',
                ignoreRowClick: true,
                button: true,
                cell: (row: any) => <>
                    <RouteLink to={"/reviewtag/update/" + (row.tagMasterId)} className="icon-right-margin"><i className="fas fa-pencil-alt"></i></RouteLink>
                    <i className="fa fa-trash show-cursor-as-pointer" onClick={() => this.deleteReviewTag(row)}></i>
                </>,
            }
        ];

        const excludeFromFilter = ['TagMasterId'];
        
        this.state = {
            reviewTagCollection: [],
            columns: columnsHeaders,
            excludeFromFilter: excludeFromFilter,
            loading: true,
            show: false,
            message: '',
            title: 'Success',
            totalRows: 0,
            perPage:10,
        };

        //this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.getListPagewise = this.getReviewTagListPagewise.bind(this);
    }

    componentDidMount() {
        //this.getReviewTagList();
        this.getReviewTagListPagewise(1, this.state.perPage);
    }

    deleteReviewTag = (reviewTag) => {

        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(DeleteReviewTagById + reviewTag.tagMasterId).then(
                (result) => {
                    if (result.data.responseInfo.success == true) {
                        //this.setState({ loading: true });
                        const indexOfItem = this.state.reviewTagCollection.indexOf(reviewTag);

                        this.state.reviewTagCollection.splice(indexOfItem, 1);
                        this.setState({ reviewTagCollection: this.state.reviewTagCollection }); //, loading: false  });
                    } else {
                        this.setState({ show: true, message: 'Error while deleting Review Tag', title: 'Error' });
                    }
                },
                (error) => {
                    if (error.response.status === 400) {
                        if (error.response.data.errors) {
                            var message = '';
                            Object.entries(error.response.data.errors).map(([key, value]) => {
                                message += value + '<br/>';
                                return value;
                            });
                            this.setState({ show: true, message: message, title: 'Error' });
                        }
                    }
                });
        }
    }

    renderReviewTagTable(reviewTagCollection) {
        return (
            <div className="card shadow mb-4">
                <CommonTableHeader url="/reviewtag/add" urlText="New Review Tag" title="Review Tags" />
                <DataTableCustomPagewise
                    columns={this.state.columns}
                    data={reviewTagCollection}
                    excludeFromFilter={this.state.excludeFromFilter}
                    totalRows={this.state.totalRows}
                    perPage={this.state.perPage}
                    getListPagewise={this.getListPagewise}
                />
            </div>

        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReviewTagTable(this.state.reviewTagCollection);

        return (
            <>
                {contents}
                <SweetAlert
                    show={this.state.show}
                    title={this.state.title}
                    html={this.state.message}
                    onConfirm={() => this.setState({ show: false })} />
            </>
        );
    }

    async getReviewTagList() {
        const response = await ReviewTagService.getReviewTagList(); // await axios.get('http://localhost:47702/api/ReviewTag/');
        const data = await response.data;
        this.setState({ reviewTagCollection: data != '' ? data : [], loading: false });
    }

    async getReviewTagListPagewise(page, perPage) {
        //debugger;
        const response = await ReviewTagService.getReviewTagListPagewise(page, perPage);
        const data = await response.data;
        this.setState({ reviewTagCollection: data.tListResult != '' ? data.tListResult : [], loading: false });
        if (page == 1) {
            this.setState({ totalRows: data.totalRecords });
        }

    }
}