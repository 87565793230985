
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { authenticationService } from '../services/AuthenticationService';
import {  NavItem, Form } from 'react-bootstrap';
import axios from 'axios';
import { GetAllProperty } from '../services/BaseService';

export default function TopNav(props) {

	const [Properties, setProperties] = useState([]);
	const [PropertyId, setPropertyId] = useState(parseInt(authenticationService.currentUserValue.propertyId));
	const history = useHistory();
	const [refreshPropertyDropdownFor, setRefreshPropertyDropdownFor] = useState(['/home', '/property', '/users', '/reviews', '/user/add', '/review/add']) //
	const [showPropertyDropdownFor, setShowPropertyDropdownFor] = useState(['/users', '/reviews', '/user/add', '/review/add'])
	useEffect(() => {
		//debugger;
		if (authenticationService.IsMasterAdmin == true && (refreshPropertyDropdownFor.indexOf(props.currentLink ) > -1)) {
			axios.get(GetAllProperty).then(
				(response) => {
					if (response.data) {
						setProperties(response.data);
						if (response.data.length > 0 && (authenticationService.currentUserValue.propertyId == "" || authenticationService.currentUserValue.propertyId == 0)) {
							setSelectedProperty(response.data[0].id);
						}
					}
				},
				(error) => {

				});
		}

	}, [props.currentLink]);

	const changeProperty = (event) => {
		//var user = authenticationService.currentUserValue;
		//user.propertyId = event.target.value;
		//authenticationService.setUser(user);
		//setPropertyId(event.target.value);
		setSelectedProperty(event.target.value);
		window.location.reload(false);
	}

	const setSelectedProperty = (propertyId) => {
		var user = authenticationService.currentUserValue;
		user.propertyId = propertyId;
		authenticationService.setUser(user);
		setPropertyId(propertyId);
	}

	const logout = () => {
		authenticationService.logout();
		history.push('/login');
	}

	return (
		<>
		<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<i className="fa fa-bars" />
			</button>

			{/*<form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">*/}
			{/*	<div className="input-group">*/}
			{/*		<input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />*/}
			{/*		<div className="input-group-append">*/}
			{/*			<button className="btn btn-primary" type="button">*/}
			{/*				<i className="fas fa-search fa-sm" />*/}
			{/*			</button>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</form>*/}

			

			<ul className="navbar-nav ml-auto">

				<div className="center-box">
						{authenticationService.IsMasterAdmin == true && (showPropertyDropdownFor.indexOf(props.currentLink) > -1)? 
						(
							<NavItem>
								<Form.Select onChange={changeProperty} value={PropertyId}>
										<option key="0" value="" disabled>Please select property</option>

									{Properties.map((item, i) => {
										return (
											<option key={i} value={item.id}>{item.name}</option>
										)
									})}
								</Form.Select>
							</NavItem>
						) : ''
					}
				</div>
				{/*<li className="nav-item dropdown no-arrow d-sm-none">*/}
				{/*	<a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
				{/*		<i className="fas fa-search fa-fw" />*/}
				{/*	</a>*/}
				{/*	<div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">*/}
				{/*		<form className="form-inline mr-auto w-100 navbar-search">*/}
				{/*			<div className="input-group">*/}
				{/*				<input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />*/}
				{/*				<div className="input-group-append">*/}
				{/*					<button className="btn btn-primary" type="button">*/}
				{/*						<i className="fas fa-search fa-sm" />*/}
				{/*					</button>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</form>*/}
				{/*	</div>*/}
				{/*</li>*/}

				


				<div className="topbar-divider d-none d-sm-block" />
				<li className="nav-item dropdown no-arrow">
					<a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span className="mr-2 d-none d-lg-inline content-light-gray-color small">{authenticationService.displayUserName}<br />{authenticationService.displayPropertyName}</span>
						<img className="img-profile rounded-circle" src="../../img/undraw_profile.svg" />
					</a>
					<div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
						{/*<a className="dropdown-item" href="#">*/}
						{/*	<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />*/}
      {/*                              Profile*/}
      {/*                          </a>*/}
						{/*<a className="dropdown-item" href="#">*/}
						{/*	<i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />*/}
      {/*                              Settings*/}
      {/*                          </a>*/}
						{/*<a className="dropdown-item" href="#">*/}
						{/*	<i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />*/}
      {/*                              Activity Log*/}
      {/*                          </a>*/}
						{/*<div className="dropdown-divider" />*/}

						{/*<a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" >*/}
							<a className="dropdown-item" href="#" data-toggle="modal" onClick={logout} >
							<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                    Logout
                                </a>
					</div>
				</li>
			</ul>
			</nav>

			<div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Logout!</h5>
							<button className="close" type="button" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">Do you want to "Logout" from Guest Review System?</div>
						<div className="modal-footer">
							<button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
							<button className="btn btn-primary" type="button" data-dismiss="modal" onClick={logout}>Logout</button>
						</div>
					</div>
				</div>
			</div>
		</>
    );
}