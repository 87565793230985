import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { UserBaseUrl } from "../../services/BaseService";
//import Typography from '@mui/material/Typography';
//import Breadcrumbs from '@mui/material/Breadcrumbs';

const schema = yup.object().shape({
    email: yup.string().required('Please enter email').email("Enter valid email"),
    password: yup.string().required('Please enter password')
        .max(20, "Password must be of max 20 characters long")
        .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    ,
    username: yup.string().required('Please enter username'),
    firstName: yup.string().required('Please enter firstname'),
    lastName: yup.string().required('Please enter lastname'),
    mobile: yup.string().required('Please enter mobile number'),
    userRole: yup.string().required('Please select user role')
});

export default function AddUser() {
    const [data, setData] = useState({
        email: '',
        password: '',
        username: '',
        firstName: '',
        lastName: '',
        mobile: '',
        userRole: '',
        propertyId: authenticationService.currentUserValue.propertyId,
        createdbyuserid: authenticationService.currentUserValue.userId
    });
    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [userRoleCollection, setUserRoleCollection] = useState([{ "id": "2", "rolename": "Owner" }, { "id": "3", "rolename": "Manager" }, { "id": "4", "rolename": "Staff" }]); //

    let history = useHistory();

    useEffect(() => {
        //getUserAvailableRoles();
    }, []);

    //const getUserAvailableRoles = async () => {
    //    //debugger;
    //    //alert("Manager = " + localStorage.getItem("remainingManagerRoleCount") + "    staff = " + localStorage.getItem("remainingStaffRoleCount"))

    //    let roleCollection = userRoleCollection;
    //    if (localStorage.getItem("remainingManagerRoleCount") > 0) {

    //        roleCollection = roleCollection.concat([{ "id": "3", "rolename": "Manager" }])
    //    }

    //    if (localStorage.getItem("remainingStaffRoleCount") > 0) {
    //        roleCollection = roleCollection.concat([{ "id": "4", "rolename": "Staff" }])
    //    }

    //    setUserRoleCollection(roleCollection);
    //}

    const handleSubmit = (values, actions) => {
        axios.post(UserBaseUrl, values).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success==true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    history.push("/users");
                } else {
                    //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
                    setShowHide(true);
                    setMessage(result.data.responseInfo.message); //'Error while trying to add user');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    };
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <BreadcrumbsNav url = "/users" urlText = "Users" typographyText ="New User"/>

                {/*<div className="row">*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <h6 className="m-0 font-weight-bold text-primary float-start">New User</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <RouteLink to={"/users"} className="float-end">*/}
                {/*            <Button> User List </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="card-body">
            
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">

                                <Field name="firstName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control type="text" {...field} id="firstName" isInvalid={!!errors.firstName} autoFocus/>
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="lastName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.lastName && touched.lastName} as={Col}>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" {...field} id="lastName" isInvalid={!!errors.lastName} />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">

                                <Field name="email">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.email && touched.email} as={Col}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" {...field} id="email" isInvalid={!!errors.email} />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="mobile">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.mobile && touched.mobile} as={Col}>
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control type="text" {...field} id="mobile" isInvalid={!!errors.mobile} />
                                            <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                                <Field name="username">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.username && touched.username} as={Col}>
                                            <Form.Label>User name</Form.Label>
                                            <Form.Control type="text" {...field} id="username" isInvalid={!!errors.username} />
                                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="password">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.password && touched.password} as={Col}>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" {...field} id="password" isInvalid={!!errors.password} />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                                <Field name="userRole">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.userRole && touched.userRole} as={Col}>
                                            <Form.Label>User Role</Form.Label>
                                            <Form.Select {...field} id="userRole" isInvalid={!!errors.userRole}>
                                                <option key="0" value="">Please select user role</option>
                                                {userRoleCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.rolename}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.userRole}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field >
                                    {({ field, form }) => (
                                        <Form.Group as={Col}>
                                            
                                        </Form.Group>
                                    )}
                                </Field>

                            </Row>
                            <SaveButton buttonText="Save User" />
                        </Form>
                )}
                </Formik>
            </div>
        <SweetAlert
            show={show}
            title={title}
            html={message}
            onConfirm={() => setShowHide(false)} />
        </div>
    );
}