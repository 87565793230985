
import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouteLink } from 'react-router-dom';

export default function BreadcrumbsNav(props) {

    return (        
        <Breadcrumbs aria-label="breadcrumb">
            <RouteLink to={props.url} className="cursor-pointer text-800 table-header-title-text  breadcrumbs-nav-route-link ">
                {props.urlText}
            </RouteLink>
            <Typography className="text-gray-800 table-header-title-text" color="text.primary">{props.typographyText}</Typography>
        </Breadcrumbs>
    );
}