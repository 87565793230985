import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { GetPropertyById, GetCountry, GetStateByCountryId, GetCityByStateId } from './BaseService';

//const currentUserSubject = new BehaviorSubject({ username: 'Vijay', roles: ['Admin'], userId: 2, propertyId: 4 });
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const PropertyService = {
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    isAuthenticated: function () {
        if (currentUserSubject.value && currentUserSubject.value != null)
            return true;
        else
            return false;
    },
    get Role() { return currentUserSubject.value != null ? currentUserSubject.value.roles[0] : '' },
    //getAdminMenu,
    //getStaffMenu,
    //APIURL: {
    //    addPostProperty: env.API_CALL_URL + + 'Property', 
    //    updatePutProperty: env.API_CALL_URL + 'Property',
    //    getPropertyList: env.API_CALL_URL + 'Property',
    //    getByIdProperty: env.API_CALL_URL + 'Property/GetById',
    //},
    //addProperty,
    //updateProperty,
    //getPropertyList,
    getPropertyByIdList,
    getCountryList,
    getStateList,
    getCityList
}

//addProperty = (Property) => {
//    return axios.post(this.APIURL.addProperty, Property).then(
//        (result) => {
//            if (!result.data.errors) {
//            }
//        },
//        (error) => {
//            alert(error.response.data);
//        });
//}

//updateProperty = (Property) => {
//    return axios.post(this.APIURL.updateProperty, Property).then(
//        (result) => {
//            if (!result.data.errors) {
//            }
//        },
//        (error) => {
//            alert(error.response.data);
//        });
//}

//getPropertyList = (Property) => {
//    return axios.post(this.APIURL.getPropertyList, Property).then(
//        (result) => {
//            if (!result.data.errors) {
//            }
//        },
//        (error) => {
//            alert(error.response.data);
//        });
//}

async function getPropertyByIdList(propertyId) {
    return await axios.get(GetPropertyById + propertyId);
}

async function getCountryList() {
    return await axios.get(GetCountry);
    //return await axios.get('http://localhost:47702/api/Property/GetAllCountry');
}

async function getStateList(countryId) {
    return await axios.get(GetStateByCountryId + countryId);
    //return await axios.get('http://localhost:47702/api/Property/GetStateByCountryId?countryId=' + countryId);
}
async function getCityList(stateId) {
    return await axios.get(GetCityByStateId + stateId);
    //return await axios.get('http://localhost:47702/api/Property/GetCityByStateId?stateId=' + stateId);
}