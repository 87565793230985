import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { Base_ApiUrl, Login_API, Logout_API, Forgot_Password_API, NavDashboard, NavProperties, NavUsers, NavReviews, NavSearchReview, NavReviewTags } from './BaseService';

//const currentUserSubject = new BehaviorSubject({username:'Vijay',userRole:'MasterAdmin',userId:2,propertyId:4});
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
//const API_BaseURL = Base_ApiUrl;//"http://localhost:47702/api/";



export const authenticationService = {
    login,
    logout,
    setUser,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value && currentUserSubject.value != null ? currentUserSubject.value : {}
    },
    isAuthenticated: function () {
        if (currentUserSubject.value && currentUserSubject.value != null)
            return true;
        else
            return false;
    },
    get Role() { return currentUserSubject.value != null ? currentUserSubject.value.userRole : '' },
    getAdminMenu,
    getOwnerMenu,
    getManagerMenu,
    getStaffMenu,
    APIURL: {
        loginPost: Login_API, //BaseService.API_URL + 'account',
        logoutPost: Logout_API,// API_BaseURL + 'logout',
        forgotPasswordPost: Forgot_Password_API, //Base_ApiUrl + 'forgotpassword',
    },
    //MenuNav: {
    //    Dashboard: NavDashboard,
    //    Properties: NavProperties,
    //    Users: NavUsers,
    //    Reviews: NavReviews,
    //    SearchReview: NavSearchReview,
    //    ReviewTags: NavReviewTags
    //},
    get displayUserName() { return currentUserSubject.value != null ? currentUserSubject.value.firstName + ' ( ' + currentUserSubject.value.userRole + ' ) ' : 'No User' },
    get displayPropertyName() { return currentUserSubject.value != null ? currentUserSubject.value.propertyName : 'No Property' },
    get IsMasterAdmin() { return currentUserSubject.value != null ? currentUserSubject.value.userRole == 'MasterAdmin' : false },
    checkPassedRolesMatched
};

function login(loginRequest) {
    return axios.post(this.APIURL.loginPost, loginRequest);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
function setUser(user) {
    //debugger;
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function getAdminMenu() {
    return [
        { displayName: 'Dashboard', link: NavDashboard, icon: 'fas fa-fw fa-tachometer-alt' },
        { displayName: 'Properties', link: NavProperties, icon: 'fa fa-bed' },
        { displayName: 'Users', link: NavUsers, icon: 'fa fa-users' },
        { displayName: 'Reviews', link: NavReviews, icon: 'fa fa-id-card' },
        { displayName: 'Search Review', link: NavSearchReview, icon: 'fa fa-search' },
        { displayName: 'Review Tags', link: NavReviewTags, icon: 'fa fa-tags' }];
}

function getOwnerMenu() {
    return [
        { displayName: 'Dashboard', link: NavDashboard, icon: 'fas fa-fw fa-tachometer-alt' },
        { displayName: 'Users', link: NavUsers, icon: 'fa fa-users' },
        { displayName: 'Reviews', link: NavReviews, icon: 'fa fa-id-card' },
        { displayName: 'Search Review', link: NavSearchReview, icon: 'fa fa-search' }];
}

function getManagerMenu() {
    return [
        { displayName: 'Dashboard', link: NavDashboard, icon: 'fas fa-fw fa-tachometer-alt' },
        { displayName: 'Reviews', link: NavReviews, icon: 'fa fa-id-card' },
        { displayName: 'Search Review', link: NavSearchReview, icon: 'fa fa-search' }];
}

function getStaffMenu() {
    return [
        { displayName: 'Dashboard', link: NavDashboard, icon: 'fas fa-fw fa-tachometer-alt' },
        { displayName: 'Search Review', link: NavSearchReview, icon: 'fa fa-search' }];
}

function checkPassedRolesMatched(userRoles: string) {
    var Role = currentUserSubject.value != null ? currentUserSubject.value.userRole : '';
    if (userRoles && userRoles != '') {
        var roles = userRoles.split(',');
        if (roles.indexOf(Role)>-1)
            return true;
    } else { return true;}
    return false;
}
