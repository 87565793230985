import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { Component } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { PropertyBaseUrl } from "../../services/BaseService";
import { PropertyService } from "../../services/PropertyService";

const schema = yup.object().shape({
    propertyName: yup.string().required('Please enter property name'),
    address: yup.string().required('Please enter address'),
    cityId: yup.number().required('Please enter city').positive().integer(),
    stateId: yup.number().required('Please enter state').positive().integer(),
    countryId: yup.number().required('Please enter country').positive().integer(),
    zip: yup.string().required('Please enter zip'),
    phone: yup.string().required('Please enter phone'),
    allowedManager: yup.number().required('Please enter allowed manager').positive().integer(),
    allowedStaff: yup.number().required('Please enter allowed staff').positive().integer(),
    website: yup.string().required('Please enter website')
});


export class EditProperty extends Component {
    constructor(props) {
        super(props);

        const defaultData = {
            propertyName: '',
            address: '',
            cityId: '',
            stateId: '',
            countryId: '',
            zip: '',
            phone: '',
            allowedManager: '',
            allowedStaff: '',
            website: '',
            propertyId: authenticationService.currentUserValue.propertyId,
            createdByUserId: authenticationService.currentUserValue.userId
        };
        
        const { id } = this.props.match.params;
        this.state = {
            property: defaultData, isValueLoaded: false, show: false, message: '', title: 'Success', propertyId: id, loading: true,
            countryCollection: [{ label: "Loading ...", value: "" }], stateCollection: [], cityCollection: []
        };
    }

    componentDidMount() {
        this.getPropertyById();
    }

    getPropertyById = async () => {
        const response = await PropertyService.getPropertyByIdList(this.state.propertyId); // await axios.get(GetPropertyById + this.state.propertyId);
        const data = await response.data.property.tResult;
        if (data && data.propertyId > 0) {
            this.setState({ property: data, isValueLoaded: true });
            this.getCountryList();
        }
        else {
            this.props.history.push("/property");
        }
    }

    getCountryList = async () => {
        const response = await PropertyService.getCountryList(); //await axios.get(GetCountry);
        this.setState({ countryCollection: await response.data.countryCollection.tListResult, loading: false });
        if (this.state.property.countryId != '') {
            this.getStateList(this.state.property.countryId);
        }
    }

    getStateList = async (countryId) => {
        const response = await PropertyService.getStateList(countryId); //await axios.get(GetStateByCountryId + countryId);
        this.setState({ stateCollection: await response.data.stateCollection.tListResult });
        if (this.state.property.stateId != '') {
            this.getCityList(this.state.property.stateId);
        }
    }

    getCityList = async (stateId) => {
        const response = await PropertyService.getCityList(stateId); //await axios.get(GetCityByStateId + stateId);
        this.setState({ cityCollection: await response.data.cityCollection.tListResult });
    }

    handleCountryChange = (form, event) => {
        //const data = this.state.property
        //data.stateId = '';
        //data.cityId = '';
        //this.setState({ property: data });
        this.state.property.stateId = '';
        this.state.property.cityId = '';

        form.setFieldValue('stateId', '');
        form.setFieldValue('cityId', '');

        this.setState({ cityCollection: [] });
        this.getStateList(event.target.value);
    };

    handleStateChange = (form, event) => {
        //this.state.property.cityId = '';
        form.setFieldValue('cityId', '');
        this.getCityList(event.target.value);
    };

    handleSubmit = (values, actions) => {
        axios.put(PropertyBaseUrl, values).then(
            (result) => {
                actions.setSubmitting(false);
                if (result.data.responseInfo.success == true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    this.props.history.push("/property");
                } else {
                    this.setState({ show: true, message: 'Error while updating property', title: 'Error' });
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        this.setState({ show: true, message: message, title: 'Error' });
                    }
                }
                actions.setSubmitting(false);
            });
    };

    render() {
        return !this.state.isValueLoaded
            ? <p><em>Loading...</em></p>
            : (
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <BreadcrumbsNav url="/property" urlText="Properties" typographyText="Update Property" />

                        {/*<div className="row">*/}
                        {/*    <div className="col align-self-center">*/}
                        {/*        <h6 className="m-0 font-weight-bold text-primary float-start">Update Property</h6>*/}
                        {/*    </div>*/}
                        {/*    <div className="col align-self-center">*/}
                        {/*        <RouteLink to={"/property"} className="float-end">*/}
                        {/*            <Button> Property List </Button>*/}
                        {/*        </RouteLink>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="card-body">
                        <Formik
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                            initialValues={this.state.property}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                actions
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Row className="mb-3">

                                        <Field name="propertyName">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.propertyName && touched.propertyName} as={Col}>
                                                    <Form.Label>Property name</Form.Label>
                                                    <Form.Control type="text" {...field} id="propertyName" isInvalid={!!errors.propertyName} autoFocus/>
                                                    <Form.Control.Feedback type="invalid">{errors.propertyName}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="address">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.address && touched.address} as={Col}>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" {...field} id="address" isInvalid={!!errors.address} />
                                                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                    </Row>

                                    <Row className="mb-3">
                                        <Field name="countryId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.countryId && touched.countryId} as={Col} onChange={(e) => { this.handleCountryChange(form, e); }}>
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Select {...field} id="countryId" isInvalid={!!errors.countryId} disabled={this.state.loading} >

                                                        <option key="0" value="">Please select country</option>

                                                        {this.state.countryCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}

                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.countryId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="stateId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.stateId && touched.stateId} as={Col} onChange={(e) => { this.handleStateChange(form, e); }}>
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Select {...field} id="stateId" isInvalid={!!errors.stateId} disabled={this.state.loading} >
                                                        <option key="0" value="">Please select state</option>
                                                        {this.state.stateCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">
                                        <Field name="cityId">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.cityId && touched.cityId} as={Col}>
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Select {...field} id="cityId" isInvalid={!!errors.cityId} disabled={this.state.loading} >
                                                        <option key="0" value="">Please select city</option>
                                                        {this.state.cityCollection.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">{errors.cityId}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>


                                        <Field name="zip">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.zip && touched.zip} as={Col}>
                                                    <Form.Label>Zip</Form.Label>
                                                    <Form.Control type="text" {...field} id="zip" isInvalid={!!errors.zip} />
                                                    <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">
                                        <Field name="phone">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.phone && touched.phone} as={Col}>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control type="text" {...field} id="phone" isInvalid={!!errors.phone} />
                                                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="allowedManager">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.allowedManager && touched.allowedManager} as={Col}>
                                                    <Form.Label>Allowed Manager</Form.Label>
                                                    <Form.Control type="text" {...field} id="allowedManager" isInvalid={!!errors.allowedManager} />
                                                    <Form.Control.Feedback type="invalid">{errors.allowedManager}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>

                                    <Row className="mb-3">
                                        <Field name="allowedStaff">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.allowedStaff && touched.allowedStaff} as={Col}>
                                                    <Form.Label>Allowed Staff</Form.Label>
                                                    <Form.Control type="text" {...field} id="allowedStaff" isInvalid={!!errors.allowedStaff} />
                                                    <Form.Control.Feedback type="invalid">{errors.allowedStaff}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="website">
                                            {({ field, form }) => (
                                                <Form.Group isInvalid={errors.website && touched.website} as={Col}>
                                                    <Form.Label>Website</Form.Label>
                                                    <Form.Control type="text" {...field} id="website" isInvalid={!!errors.website} />
                                                    <Form.Control.Feedback type="invalid">{errors.website}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Row>
                                    <SaveButton buttonText="Update Property" />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        title={this.state.title}
                        html={this.state.message}
                        onConfirm={() => this.setState({ show: false })} />
                </div>
            );
    }
}