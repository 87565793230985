import axios from "axios";
import React, { Component } from "react";
import { Link as RouteLink } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import CommonTableHeader from "../../components/CommonUIs";
import DataTableCustomPagewise from "../../components/DataTableCustomPagewise";
import { authenticationService } from "../../services/AuthenticationService";
import { DeleteUserById, UserByPropertyIdPagewise, UserByPropertyIdUrl } from "../../services/BaseService";


export default class UserList extends Component {

    constructor(props) {
        super(props);

        const columnsHeaders = [
            {
                name: 'First Name',
                selector: row => row.firstName,
                sortable: true,
            },
            {
                name: 'Last Name',
                selector: row => row.lastName,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Role',
                selector: row => this.renderUserRole(row.userRole),
                sortable: true,
            },            
            {
                name: '',
                ignoreRowClick: true,
                button: true,
                cell: (row: any) => <>
                    <RouteLink to={"/user/update/" + (row.userId)} className="icon-right-margin"><i className="fas fa-pencil-alt"></i></RouteLink>
                    <i className="fa fa-trash show-cursor-as-pointer" onClick={() => this.deleteUser(row)}></i>                    
                </>,
            }
        ];

        const excludeFromFilter = ['UserId', 'Username', 'Password', 'Mobile', 'UserRole', 'CreatedDateTime', 'CreatedByUserId', 'PropertyId'];
        props.addCurrentLink(window.location.pathname);
        this.state = {
            users: [],
            columns: columnsHeaders,
            excludeFromFilter: excludeFromFilter,
            loading: true,
            show: false, message: '',
            title: 'Success',
            isDisabled: false,
            totalRows: 0,
            perPage: 10,
        };
        
        //this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.getListPagewise = this.getUserListPagewise.bind(this);
    }

    componentDidMount() {
        //this.getUserList();
        this.getUserListPagewise(1, this.state.perPage);
    }

    deleteUser = (user) => {
        
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(DeleteUserById +user.userId).then(
                (result) => {
                    
                    if (result.data.success == true) {

                        //this.setState({ loading: true });

                        const indexOfItem = this.state.users.indexOf(user);

                        this.state.users.splice(indexOfItem, 1);
                        this.setState({ users: this.state.users }); //, loading: false });
                    } else {
                        this.setState({ show: true, message: 'Error while deleting user', title: 'Error' });
                    }
                },
                (error) => {
                    if (error.response.status === 400) {
                        if (error.response.data.errors) {
                            var message = '';
                            Object.entries(error.response.data.errors).map(([key, value]) => {
                                message += value + '<br/>';
                                return value;
                            });
                            this.setState({ show: true, message: message, title: 'Error' });
                        }
                    }
                });
        }
    }

    renderUserRole(userRole) {
        switch (userRole) {
            case 2:
                return 'Owner';
            case 3:
                return 'Manager';
            case 4:
                return 'Staff';
            default:
                return 'None';
        }
    }

    renderUsersTable(users) {        
        return (
            <div className="card shadow mb-4">
                <CommonTableHeader url={authenticationService.currentUserValue.propertyId == 0 ? "" : "/user/add"} urlText="New User" title="Users" />
                <DataTableCustomPagewise
                    columns={this.state.columns}
                    data={users}
                    excludeFromFilter={this.state.excludeFromFilter}
                    totalRows={this.state.totalRows}
                    perPage={this.state.perPage}
                    getListPagewise={this.getListPagewise}
                />
            </div>

        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable(this.state.users);

        return (
            <>
                {/*<CardHeader className="bg-primary">*/}
                {/*    <h3 className="float-start text-light">User List</h3>*/}
                {/*    <RouteLink to={"/user/add"} className="float-end" style={{ display: this.state.isDisabled ? 'none' : 'block' }}>*/}
                {/*        <Button variant="dark">*/}
                {/*                Add New User </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </CardHeader>*/}
                
                {contents}
                    
                <SweetAlert
                    show={this.state.show}
                    title={this.state.title}
                    html={this.state.message}
                    onConfirm={() => this.setState({ show: false })} />
             </>
        );
    }

    async getUserList() {
        const response = await axios.get(UserByPropertyIdUrl + authenticationService.currentUserValue.propertyId);
        const data = await response.data;
        this.setState({ users: data.userCollection != '' ? data.userCollection : [], loading: false });
        if (this.state.users != '') {
            this.state.users.map((user) => {
                user.userRoleName = this.renderUserRole(user.userRole);
            });
        }
    }

    async getUserListPagewise(page, perPage) {
        const response = await axios.get(UserByPropertyIdPagewise + authenticationService.currentUserValue.propertyId + "&page=" + page + "&perPage=" + perPage);
        const data = await response.data;
        //debugger;
        this.setState({ users: data.userCollection.tListResult != '' ? data.userCollection.tListResult : [], loading: false });
        if (page == 1) {
            this.setState({ totalRows: data.userCollection.totalRecords });
        }

        if (this.state.users != '') {
            this.state.users.map((user) => {
                user.userRoleName = this.renderUserRole(user.userRole);
            });
        }
    }
}