import React from 'react';
import { Button} from 'react-bootstrap';

export default function SaveButton(props) {

	return (
        <div className="center-button">
            <Button type="submit">{props.buttonText}</Button>
        </div>
    );
}