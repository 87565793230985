import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import DataTableCustomPagewise from '../../components/DataTableCustomPagewise';
import ImageSliderCustom from "../../components/ImageSliderCustom";
import { authenticationService } from '../../services/AuthenticationService';
import { DeleteReviewById, DownloadReviewFileByFileName, ReviewBySearhCriteriaPagewise } from "../../services/BaseService";
import { PropertyService } from "../../services/PropertyService";
import { ReviewService } from "../../services/ReviewService";
import { ReviewTagService } from "../../services/ReviewTagService";

const schema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    mobile:yup.string(),
    drivingLicenseNumber: yup.string(),
    guestEmail: yup.string(),
    reviewTags: yup.array(),
    zipcode: yup.string(),
    countryId: yup.number(),
    stateId: yup.number(),
    cityId: yup.number(),
    reviewByUser: yup.string(),
    reviewByProperty: yup.string(),
    //reviewDate: yup.date()
});

export default function ReviewSearchAdv() {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        drivingLicenseNumber: '',
        guestEmail: '',
        reviewTags: [],
        zipcode: '',
        countryId: '', // 231 assign this value whenever Country dropdown will visible
        stateId: '',
        cityId: '',
        reviewByUser: '',
        reviewByProperty: '',
        page: 0,
        perPage: 0,
        //reviewDate: new Date()
    });

    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const [loading, setLoading] = React.useState(true);
    //const [showNoRecordMessage, setShowNoRecordMessage] = React.useState(false);
    const [showSearchResultList, setShowSearchResultList] = React.useState(false);
    const [showReviewDetail, setShowReviewDetail] = React.useState(false);
    const [showDeleteReview, setShowDeleteReview] = React.useState(authenticationService.Role != "Staff");
    const [countryCollection, setCountryCollection] = useState([{ label: "Loading ...", value: "" }]);
    const [stateCollection, setStateCollection] = useState([]);
    const [cityCollection, setCityCollection] = useState([]);
    const [reviewTagCollection, setReviewTagCollection] = useState([]);
    const [reviewFilesCollection, setReviewFilesCollection] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [selectedReview, setSelectedReview] = useState({});
    const [selectedReviewTags, setSelectedReviewTags] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [values, setValues] = useState([]);
//    const [{ firstName, lastName, mobile, drivingLicenseNumber, guestEmail, reviewTags, zipcode, countryId, stateId, cityId, reviewByUser, reviewByProperty }, setState] = useState(data);

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Zip',
            selector: row => row.zipCode,
            sortable: true,
        },
        {
            name: 'Review By',
            selector: row => row.reviewByProperty,
            sortable: true,
            wrap: true,
        },
        {
            name: '',
            ignoreRowClick: true,
            button: true,
            cell: (row: any) => <>
                <i className="fa fa-id-card show-cursor-as-pointer" onClick={() => setReviewDetail(row)} data-toggle="modal" data-target="#reviewDetailModal"></i>
            </>,
        }
    ];

    const excludeFromFilter = ['GuestId', 'CityId', 'StateId', 'CountryId', 'DrivingLicenseNumber', 'Email', 'CreatedByUserId', 'CreatedByPropertyId', 'CreatedDateTime',
        'GuestReviewId', 'ReviewTags', 'ReviewText', 'ReviewByUserId', 'ReviewByPropertyId', 'IsActive', 'Country', 'State', 'ReviewByUser'];

    useEffect(() => {
        //getCountryList();
        //getReviewTagList();
        
        async function getCountryList() {
            const response = await PropertyService.getCountryList(); 
            setCountryCollection(response.data.countryCollection.tListResult);
            setLoading(false);

            if (parseInt(data.countryId) > 0) {
                getStateList(data.countryId);
            }
        }

        async function getReviewTagList() {
            const response = await ReviewTagService.getReviewTagList(); 
            setReviewTagCollection(await response.data);
        }
    }, []);

    const handleCountryChange = (event) => {
        data.stateId = '';
        data.cityId = '';
        setCityCollection([])
        getStateList(event.target.value);
    };

    const IsFileTypeImage = (fileName) => {
        var ext = fileName.split('.').pop();
        var extSmaller = ext.toString().toLowerCase();
        const allowedFilesInPreview = ["jpg", "bmp", "jpeg", "gif", "png"]
        return allowedFilesInPreview.includes(extSmaller);
        //if (allowedFilesInPreview.includes(extSmaller)) {
        //    return true;
        //}
        //else {
        //    return false;
        //}        
    }

    async function getStateList(countryId) {
        const response = await PropertyService.getStateList(countryId); 
        setStateCollection(response.data.stateCollection.tListResult);
    }

    const handleStateChange = (event) => {
        data.cityId = '';
        getCityList(event.target.value);

        async function getCityList(stateId) {
            const response = await PropertyService.getCityList(stateId);
            setCityCollection(response.data.cityCollection.tListResult);
        }

    };

    const handleSubmit = (values, actions) => {
        resetReviewList();
        getReviewsByGuestFilter(1, perPage, values, actions)

        //axios.post(ReviewBySearhCriteria, values).then(
        //    (result) => {
        //        //debugger;
        //        actions.setSubmitting(false);
        //        if (result.data.reviewCollection.success == true) {
        //            const data = result.data.reviewCollection;
        //            setReviews(data != '' ? data.tListResult : []);
        //            setShowSearchResultList(true);  //data != '' && data.tListResult.length>0
        //            //setShowNoRecordMessage(data == '' || data.tListResult.length==0);
        //            //history.push("/users");
        //        } else {
        //            //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
        //            setShowHide(true);
        //            setMessage('Error while trying to search review');
        //            setTitle('Error');
        //        }
        //    },
        //    (error) => {
        //        if (error.response.status === 400) {
        //            if (error.response.data.errors) {
        //                var message = '';
        //                Object.entries(error.response.data.errors).map(([key, value]) => {
        //                    message += value + '<br/>';
        //                    return value;
        //                });
        //                setShowHide(true);
        //                setMessage(message);
        //                setTitle('Error');
        //            }
        //        }
        //        actions.setSubmitting(false);
        //    });
    };

    const getReviewsByGuestFilter = (page, perPage, searchValues, actions) => {
        //debugger;
        if (searchValues === undefined) {
            searchValues = values; // restore searched values
        }
        searchValues.page = page;
        searchValues.perPage = perPage;
        setValues(searchValues);

        axios.post(ReviewBySearhCriteriaPagewise, searchValues).then(
            (result) => {
                if (actions != null && actions !== undefined) {
                    actions.setSubmitting(false);
                }
                if (result.data.reviewCollection.success == true) {
                    const data = result.data.reviewCollection;
                    setReviews(data.tListResult != '' ? data.tListResult : []);
                    setShowSearchResultList(true); 
                    if (page == 1) {
                        setTotalRows(data.totalRecords);
                    }
                } else {
                    //this.setState({ show: true, message: 'Error while updating user', title: 'Error' });
                    setShowHide(true);
                    setMessage('Error while trying to search review');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                if (actions != null) {
                    actions.setSubmitting(false);
                }
            });
    };

    const getReviewFiles = (guestReviewId) => {
        //debugger;
        getReviewFilesList(guestReviewId);
        async function getReviewFilesList(guestReviewId) {
            const result = await ReviewService.getReviewFilesList(guestReviewId);
            if (result.data.success == true) {
                const data = result.data.tListResult;
                setReviewFilesCollection(data != '' ? data : []);
            } else {
                setReviewFilesCollection([]);
            }
        }
    };

    const messagesEndRef = React.createRef();
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const setReviewDetail = (review) => {
        getReviewFiles(review.guestReviewId);
        setSelectedReview(review);
        setSelectedReviewTags(JSON.parse(review.reviewTags));
        setShowReviewDetail(true);
        scrollToBottom();
    };

    const clearFormState = (form, event) => {
        //debugger;
        const fields = ["firstName", "lastName", "drivingLicenseNumber", "mobile"]
        fields.map((item, i) => { form.setFieldValue(item, ''); });
        resetReviewList();
    };

    const resetReviewList = () => {
        setReviews([]);
        setReviewFilesCollection([]);
        setSelectedReview({});
        setShowSearchResultList(false);
        setShowReviewDetail(false);
        //setShowNoRecordMessage(false);
    };

    const deleteReview = (review) => {
        if (window.confirm('Are you sure to delete this record?')) {
            //axios.delete(ReviewBaseUrl, { data: review }).then(
            //debugger;
            axios.delete(DeleteReviewById + review.guestReviewId + "&guestId=" + review.guestId).then(
                (result) => {
                    if (result.data.success == true) {
                        const indexOfItem = reviews.indexOf(review);
                        reviews.splice(indexOfItem, 1);
                        setSelectedReview({});
                        setReviews(reviews);
                    } else {
                        setShowHide(true);
                        setMessage('Error while deleting user');
                        setTitle('Error');
                    }
                },
                (error) => {
                    if (error.response.status === 400) {
                        if (error.response.data.errors) {
                            var message = '';
                            Object.entries(error.response.data.errors).map(([key, value]) => {
                                message += value + '<br/>';
                                return value;
                            });
                            setShowHide(true);
                            setMessage(message);
                            setTitle('Error');
                        }
                    }
                });
        }
    }

    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0  text-gray-800 table-header-title-text float-start">Search Review</h6>
            </div>

            <div className="card-body">
            
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,

                    }) => (
                        <Form noValidate id="search-form" onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Field name="firstName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control type="text" {...field} id="firstName" isInvalid={!!errors.firstName} autoFocus/>
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="lastName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.lastName && touched.lastName} as={Col}>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" {...field} id="lastName" isInvalid={!!errors.lastName} />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            {/*<Row className="mb-3">*/}
                            {/*    <Field name="countryId">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.countryId && touched.countryId} as={Col} onChange={handleCountryChange}>*/}
                            {/*                <Form.Label>Country</Form.Label>*/}
                            {/*                <Form.Select {...field} id="countryId" isInvalid={!!errors.countryId} disabled={loading} >*/}

                            {/*                    <option key="0" value="0">Please select country</option>*/}

                            {/*                    {countryCollection.map((item, i) => {*/}
                            {/*                        return (*/}
                            {/*                            <option key={i} value={item.id}>{item.name}</option>*/}
                            {/*                        )*/}
                            {/*                    })}*/}

                            {/*                </Form.Select>*/}

                            {/*                <Form.Control.Feedback type="invalid">{errors.countryId}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}

                            {/*    <Field name="stateId">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.stateId && touched.stateId} as={Col} onChange={handleStateChange}>*/}
                            {/*                <Form.Label>State</Form.Label>*/}
                            {/*                <Form.Select {...field} id="stateId" isInvalid={!!errors.stateId} disabled={loading} >*/}
                            {/*                    <option key="0" value="0">Please select state</option>*/}
                            {/*                    {stateCollection.map((item, i) => {*/}
                            {/*                        return (*/}
                            {/*                            <option key={i} value={item.id}>{item.name}</option>*/}
                            {/*                        )*/}
                            {/*                    })}*/}
                            {/*                </Form.Select>*/}

                            {/*                <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}
                            {/*</Row>*/}

                            {/*<Row className="mb-3">*/}
                            {/*    <Field name="cityId">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.cityId && touched.cityId} as={Col}>*/}
                            {/*                <Form.Label>City</Form.Label>*/}
                            {/*                <Form.Select {...field} id="cityId" isInvalid={!!errors.cityId} disabled={loading} >*/}
                            {/*                    <option key="0" value="0">Please select city</option>*/}
                            {/*                    {cityCollection.map((item, i) => {*/}
                            {/*                        return (*/}
                            {/*                            <option key={i} value={item.id}>{item.name}</option>*/}
                            {/*                        )*/}
                            {/*                    })}*/}
                            {/*                </Form.Select>*/}

                            {/*                <Form.Control.Feedback type="invalid">{errors.cityId}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}

                            {/*    <Field name="zipcode">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.zipcode && touched.zipcode} as={Col}>*/}
                            {/*                <Form.Label>Zipcode</Form.Label>*/}
                            {/*                <Form.Control type="text" {...field} id="zipcode" isInvalid={!!errors.zipcode} />*/}
                            {/*                <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}
                            {/*</Row>*/}

                            <Row className="mb-3">
                                <Field name="drivingLicenseNumber">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.drivingLicenseNumber && touched.drivingLicenseNumber} as={Col}>
                                            <Form.Label>Driving license number</Form.Label>
                                            <Form.Control type="text" {...field} id="drivingLicenseNumber" isInvalid={!!errors.drivingLicenseNumber} />
                                            <Form.Control.Feedback type="invalid">{errors.drivingLicenseNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="mobile">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.mobile && touched.mobile} as={Col}>
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control type="text" {...field} id="mobile" isInvalid={!!errors.mobile} />
                                            <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                {/*<Field name="guestEmail">*/}
                                {/*    {({ field, form }) => (*/}
                                {/*        <Form.Group isInvalid={errors.guestEmail && touched.guestEmail} as={Col}>*/}
                                {/*            <Form.Label>Email</Form.Label>*/}
                                {/*            <Form.Control type="text" {...field} id="guestEmail" isInvalid={!!errors.guestEmail} />*/}
                                {/*            <Form.Control.Feedback type="invalid">{errors.guestEmail}</Form.Control.Feedback>*/}
                                {/*        </Form.Group>*/}
                                {/*    )}*/}
                                {/*</Field>*/}
                            </Row>

                            {/*<Row className="mb-3">*/}
                            {/*    <Field name="reviewByProperty">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.reviewByProperty && touched.reviewByProperty} as={Col}>*/}
                            {/*                <Form.Label>Review By Hotel</Form.Label>*/}
                            {/*                <Form.Control type="text" {...field} id="reviewByProperty" isInvalid={!!errors.reviewByProperty} />*/}
                            {/*                <Form.Control.Feedback type="invalid">{errors.reviewByProperty}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}

                            {/*    <Field name="reviewByUser">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.reviewByUser && touched.reviewByUser} as={Col}>*/}
                            {/*                <Form.Label>Review By User</Form.Label>*/}
                            {/*                <Form.Control type="text" {...field} id="reviewByUser" isInvalid={!!errors.reviewByUser} />*/}
                            {/*                <Form.Control.Feedback type="invalid">{errors.reviewByUser}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}
                            {/*</Row>*/}

                            {/*<Row className="mb-3">*/}
                            {/*    <Field name="reviewTags">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.reviewTags && touched.reviewTags} as={Col}>*/}
                            {/*                <Form.Label>Review Tag</Form.Label>*/}
                            {/*                <Form.Select multiple={true} {...field} id="reviewTags" isInvalid={!!errors.reviewTags}>*/}
                            {/*                    {reviewTagCollection.map((item, i) => {*/}
                            {/*                        return (*/}
                            {/*                            <option key={i} value={item.tagMasterId}>{item.tagName}</option>*/}
                            {/*                        )*/}
                            {/*                    })}*/}
                            {/*                </Form.Select>*/}

                            {/*                <Form.Control.Feedback type="invalid">{errors.reviewTags}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}

                            {/*    <Field name="reviewDate">*/}
                            {/*        {({ field, form }) => (*/}
                            {/*            <Form.Group isInvalid={errors.reviewDate && touched.reviewDate} as={Col}>*/}
                            {/*                <Form.Label>Review Date</Form.Label>*/}
                            {/*                <Form.Control type="text" {...field} id="reviewDate" isInvalid={!!errors.reviewDate} />*/}
                            {/*                <Form.Control.Feedback type="invalid">{errors.reviewDate}</Form.Control.Feedback>*/}
                            {/*            </Form.Group>*/}
                            {/*        )}*/}
                            {/*    </Field>*/}
                            {/*</Row>*/}
                        
                            <Row className="mb-3">
                                <Field name="searchReview">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.reviewTags && touched.reviewTags} as={Col}>
                                            <Button type="submit">Search Review</Button>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="clearSearch">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.reviewDate && touched.reviewDate} as={Col}>
                                            <Button onClick={(e) => { clearFormState(form, e); }}>Clear Search </Button>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>
                        
                        </Form>
                    )}
                </Formik>
            </div>
            <br />
            <div style={{ display: showSearchResultList ? 'block' : 'none' }}>
                <br/>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">

                        <div className="row">
                            <div className="col center-box">
                                <h6 className="m-0 text-gray-800 table-header-title-text float-start center-box ">Reviews</h6>
                            </div>
                            {/*<div className="col">*/}
                            {/*    <RouteLink to={"/reviewtag/add"} className="float-end">*/}
                            {/*        <Button> New Review Tag </Button>*/}
                            {/*    </RouteLink>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <DataTableCustomPagewise
                        columns={columns}
                        data={reviews}
                        excludeFromFilter={excludeFromFilter}
                        totalRows={totalRows}
                        perPage={perPage}
                        getListPagewise={getReviewsByGuestFilter}
                    />
                </div>
            </div>
            <div className="modal fade" id="reviewDetailModal" tabIndex="-1" role="dialog" aria-labelledby="reviewDetailModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="reviewDetailModalLabel">Review Detail</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div style={{ display: showReviewDetail ? 'block' : 'none' }}>
                                <div className="card-body">
                                    <Formik >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                    }) => (
                                            <Form noValidate>
                                                <Row className="mb-3">
                                                    <div className="end-button">
                                                        {selectedReviewTags.map((tag, index) =>
                                                            <Badge bg="primary" className="tag-margin-right">{tag}</Badge>
                                                        )}
                                                    </div>
                                                    <Col sm={4}>
                                                        <Field>
                                                        {({ field, form }) => (
                                                            <Form.Group isInvalid={errors.firstName && touched.firstName} as={Col}>
                                                                <div>
                                                                    <h3>{selectedReview.firstName} {selectedReview.lastName}</h3>
                                                                    <span className="content-light-gray-color">{selectedReview.mobile}</span>
                                                                    <hr />
                                                                    <span className="content-light-gray-color">Driving License Number: {selectedReview.drivingLicenseNumber}</span>
                                                                    <hr />
                                                                    <span className="content-light-gray-color">{selectedReview.email}</span>
                                                                    <hr />
                                                                    <h6 className="content-light-gray-color">Address</h6>
                                                                    <label className="content-light-gray-color">
                                                                        {selectedReview.address}<br />
                                                                        <span>{selectedReview.city}, {selectedReview.state}</span><br />
                                                                        <span>{selectedReview.country} - {selectedReview.zipCode}</span>
                                                                    </label>
                                                                    <div ref={messagesEndRef} />
                                                                </div>
                                                                    
                                                                <ImageSliderCustom files={reviewFilesCollection.filter(reviewFile => reviewFile.fileType==1)} downloadReviewFilePath={DownloadReviewFileByFileName} />

                                                                <Table>
                                                                    <tbody>
                                                                        {(reviewFilesCollection.filter(reviewFile => reviewFile.fileType != 1)).map((reviewFile, index) =>
                                                                            /*reviewFile.fileType != 1 &&*/
                                                                            <tr key={reviewFile.guestReviewFileId}>
                                                                                <td>
                                                                                    <a target="_blank" href={DownloadReviewFileByFileName + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} rel="noopener noreferrer" download>{reviewFile.fileName}</a>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </Table>

                                                                {/*<Table>*/}
                                                                {/*    <tbody>*/}
                                                                {/*        {reviewFilesCollection.map((reviewFile, index) =>*/}
                                                                {/*            <tr key={reviewFile.guestReviewFileId}>*/}
                                                                {/*                <td>fileType == 1 : This means it is a Image file type*/}
                                                                {/*                    {reviewFile.fileType == 1 ?*/}
                                                                {/*                        <a target="_blank" href={DownloadReviewFileByFileName + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} rel="noopener noreferrer" download><img height="100" width="100" src={DownloadReviewFileByFileName + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} /></a>*/}
                                                                {/*                        : <a target="_blank" href={DownloadReviewFileByFileName + reviewFile.fileName + "&guestReviewId=" + reviewFile.guestReviewId} rel="noopener noreferrer" download>{reviewFile.fileName}</a>}*/}
                                                                
                                                                {/*                </td>                                                            */}
                                                                {/*            </tr>*/}
                                                                {/*        )}*/}
                                                                {/*    </tbody>*/}
                                                                {/*</Table>*/}
                                                            </Form.Group>
                                                        )}
                                                    </Field>
                                                    </Col>
                                                    <Col sm={8} className="h-50">
                                                        <Field>
                                                            {({ field, form }) => (
                                                                <Form.Group isInvalid={errors.drivingLicenseNumber && touched.drivingLicenseNumber} as={Col}>

                                                                    <h4>Review</h4>
                                                                    <div className="content-light-gray-color review-height">{selectedReview.reviewText}</div>
                                                                    <hr />
                                                                    <div className="align-self-end ">
                                                                        <span className="content-light-gray-color small-capital-title">REVIEWED BY</span>
                                                                        <h5>{selectedReview.reviewByProperty}</h5>
                                                                        <label className="content-light-gray-color">
                                                                            {selectedReview.propertyAddress}<br />
                                                                            <span>{selectedReview.propertyCity}, {selectedReview.propertyState}</span><br />
                                                                            <span>{selectedReview.propertyCountry} - {selectedReview.propertyZip}</span>
                                                                        </label>
                                                                    </div>                                                                    
                                                                </Form.Group>
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>

                            
                                            </Form>
                                    )}
                                    </Formik>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" style={{ display: showDeleteReview ? 'block' : 'none' }} onClick={() => deleteReview(selectedReview)}>Delete Review</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            {/*<button type="button" className="btn btn-primary">Save changes</button>*/}
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </div>
    );
}