import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import * as yup from 'yup';
import BreadcrumbsNav from "../../components/BreadcrumbsNav";
import SaveButton from "../../components/SaveButton";
import { authenticationService } from "../../services/AuthenticationService";
import { GetCityByStateId, GetCountry, GetStateByCountryId, PropertyBaseUrl } from "../../services/BaseService";


const schema = yup.object().shape({
    propertyName: yup.string().required('Please enter property name'),
    address: yup.string().required('Please enter address'),
    cityId: yup.number().required('Please enter city'),
    stateId: yup.number().required('Please enter state'),
    countryId: yup.number().required('Please enter country'),
    zip: yup.string().required('Please enter zip'),
    phone: yup.string().required('Please enter phone'),
    allowedManager: yup.number().required('Please enter allowed manager').positive().integer(),
    allowedStaff: yup.number().required('Please enter allowed staff').positive().integer(),
    website: yup.string().required('Please enter website')
});



export default function AddProperty() {

    const [data, setData] = useState({
        propertyName: '',
        address: '',
        cityId: '',
        stateId: '',
        countryId: '',
        zip: '',
        phone: '',
        allowedManager: '',
        allowedStaff: '',
        website: '',
        createdByUserId: authenticationService.currentUserValue ? authenticationService.currentUserValue.userId : 0
    });
    
    const [show, setShowHide] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const [loading, setLoading] = React.useState(true);
    const [countryCollection, setCountryCollection] = useState([{ label: "Loading ...", value: "" }]);
    const [stateCollection, setStateCollection] = useState([]);
    const [cityCollection, setCityCollection] = useState([]);

    let history = useHistory();

    useEffect(() => {
        getCountryList();

        async function getCountryList() {
            const response = await axios.get(GetCountry);
            setCountryCollection(await response.data.countryCollection.tListResult);
            setLoading(false);
        }
       
    }, []);

    const handleCountryChange = (form,event) => {
            form.setFieldValue('stateId', '');
            form.setFieldValue('cityId', '');
            setCityCollection([]);
            getStateList(event.target.value);

            async function getStateList(countryId) {
                const response = await axios.get(GetStateByCountryId + countryId);
                setStateCollection(await response.data.stateCollection.tListResult);
            }
    };

    const handleStateChange = (form, event) => {
        form.setFieldValue('cityId', '');
        getCityList(event.target.value);

        async function getCityList(stateId) {
            const response = await axios.get(GetCityByStateId + stateId);
            setCityCollection(await response.data.cityCollection.tListResult);
        }
        
    };

    const handleSubmit = (values, actions) => {
        axios.post(PropertyBaseUrl, values).then(
            (result) => {
                //debugger;
                actions.setSubmitting(false);
                if (result.data.property.success == true) {
                    //this.setState({ show: true, message: 'Record saved successfully done!', title: 'Success' });
                    history.push("/property");
                } else {
                    //this.setState({ show: true, message: 'Error while updating property', title: 'Error' });
                    setShowHide(true);
                    setMessage('Error while trying to add property');
                    setTitle('Error');
                }
            },
            (error) => {
                if (error.response.status === 400) {
                    if (error.response.data.errors) {
                        var message = '';
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            message += value + '<br/>';
                            return value;
                        });
                        setShowHide(true);
                        setMessage(message);
                        setTitle('Error');
                    }
                }
                actions.setSubmitting(false);
            });
    };
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <BreadcrumbsNav url="/property" urlText="Properties" typographyText="New Property" />

                {/*<div className="row">*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <h6 className="m-0 font-weight-bold text-primary float-start">New Property</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col align-self-center">*/}
                {/*        <RouteLink to={"/property"} className="float-end">*/}
                {/*            <Button> Property List </Button>*/}
                {/*        </RouteLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="card-body">
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={data}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <Row className="mb-3">

                                <Field name="propertyName">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.propertyName && touched.propertyName} as={Col}>
                                            <Form.Label>Property name</Form.Label>
                                            <Form.Control type="text" {...field} id="propertyName" isInvalid={!!errors.propertyName} autoFocus/>
                                            <Form.Control.Feedback type="invalid">{errors.propertyName}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="address">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.address && touched.address} as={Col}>
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" {...field} id="address" isInvalid={!!errors.address} />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                            </Row>

                            <Row className="mb-3">

                                <Field name="countryId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.countryId && touched.countryId} as={Col} onChange={(e) => { handleCountryChange(form,e); }}>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Select {...field} id="countryId" isInvalid={!!errors.countryId} disabled={loading} >
                                            
                                                <option key="0" value="">Please select country</option>
                                                
                                                {countryCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.countryId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>

                                <Field name="stateId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.stateId && touched.stateId} as={Col} onChange={(e) => { handleStateChange(form, e); }}>
                                            <Form.Label>State</Form.Label>
                                            <Form.Select {...field} id="stateId" isInvalid={!!errors.stateId} disabled={loading} >
                                                <option key="0" value="">Please select state</option>
                                                {stateCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                            <Row className="mb-3">
                            
                                <Field name="cityId">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.cityId && touched.cityId} as={Col}>
                                            <Form.Label>City</Form.Label>
                                            <Form.Select {...field} id="cityId" isInvalid={!!errors.cityId} disabled={loading} >
                                                <option key="0" value="">Please select city</option>
                                                {cityCollection.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.cityId}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            

                                <Field name="zip">
                                    {({ field, form }) => (
                                        <Form.Group isInvalid={errors.zip && touched.zip} as={Col}>
                                            <Form.Label>Zip</Form.Label>
                                            <Form.Control type="text" {...field} id="zip" isInvalid={!!errors.zip} />
                                            <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Row>

                                <Row className="mb-3">
                                    <Field name="phone">
                                        {({ field, form }) => (
                                            <Form.Group isInvalid={errors.phone && touched.phone} as={Col}>
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control type="text" {...field} id="phone" isInvalid={!!errors.phone} />
                                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>

                                    <Field name="allowedManager">
                                        {({ field, form }) => (
                                            <Form.Group isInvalid={errors.allowedManager && touched.allowedManager} as={Col}>
                                                <Form.Label>Allowed Manager</Form.Label>
                                                <Form.Control type="text" {...field} id="allowedManager" isInvalid={!!errors.allowedManager} />
                                                <Form.Control.Feedback type="invalid">{errors.allowedManager}</Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </Row>

                                <Row className="mb-3">
                                    <Field name="allowedStaff">
                                        {({ field, form }) => (
                                            <Form.Group isInvalid={errors.allowedStaff && touched.allowedStaff} as={Col}>
                                                <Form.Label>Allowed Staff</Form.Label>
                                                <Form.Control type="text" {...field} id="allowedStaff" isInvalid={!!errors.allowedStaff} />
                                                <Form.Control.Feedback type="invalid">{errors.allowedStaff}</Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>

                                    <Field name="website">
                                        {({ field, form }) => (
                                            <Form.Group isInvalid={errors.website && touched.website} as={Col}>
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control type="text" {...field} id="website" isInvalid={!!errors.website} />
                                                <Form.Control.Feedback type="invalid">{errors.website}</Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </Row>
                            <SaveButton buttonText="Save Property"/>
                        </Form>
                    )}
                    </Formik>
                </div>

            <SweetAlert
                show={show}
                title={title}
                html={message}
                onConfirm={() => setShowHide(false)} />
        </div>
    );
}