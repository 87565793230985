import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { ReviewTagBaseUrl, DeleteReviewTagById, ReviewTagsPagewise } from './BaseService';

//const currentUserSubject = new BehaviorSubject({ username: 'Vijay', roles: ['Admin'], userId: 2, propertyId: 4 });
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const ReviewTagService = {
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    isAuthenticated: function () {
        if (currentUserSubject.value && currentUserSubject.value != null)
            return true;
        else
            return false;
    },
    get Role() { return currentUserSubject.value != null ? currentUserSubject.value.roles[0] : '' },
    //getAdminMenu,
    //getStaffMenu,
    //APIURL: {
    //    addPostProperty: env.API_CALL_URL + + 'Property', 
    //    updatePutProperty: env.API_CALL_URL + 'Property',
    //    getPropertyList: env.API_CALL_URL + 'Property',
    //    getByIdProperty: env.API_CALL_URL + 'Property/GetById',
    //},
    //addProperty,
    //updateProperty,
    //getPropertyList,
    getReviewTagList,
    getReviewTagListPagewise,
}



async function getReviewTagList() {
    return await axios.get(ReviewTagBaseUrl);
}

async function getReviewTagListPagewise(page, perPage) {
    return await axios.get(ReviewTagsPagewise + page + "&perPage=" + perPage);
}

async function deleteReviewTag(tagMasterId) {
    return await axios.get(DeleteReviewTagById + tagMasterId);
}