
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { authenticationService } from '../services/AuthenticationService';


const getMenuByRole = () => {
	const currentUser = authenticationService.currentUserValue;
	let Menus = [];
	if (currentUser != null) {
		switch (currentUser.userRole) {
			case 'Admin':
			case 'MasterAdmin':
				Menus = authenticationService.getAdminMenu();
				break;
			case 'Owner':
				Menus = authenticationService.getOwnerMenu();
				break;
			case 'Manager':
				Menus = authenticationService.getManagerMenu();
				break;
			case 'Staff':
				Menus = authenticationService.getStaffMenu();
				break;
			default:
				break;
		}
	}
	return Menus;
}

//const NavLink = ({ children }: { children: ReactNode }) => (
//	<Nav.Link as={Link}
//		to={children.link}>
//		{children.displayName}
//	</Nav.Link>
//);



export default function SideNav(props) {
	const [Links, setLinks] = useState(getMenuByRole());

	const onSideNavItemClick = (linkstring) => {
		//debugger;
		//console.log(linkstring);
		props.addCurrentLink(linkstring);
	}
	
    return (
		<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
			<Link to="/home" className="sidebar-brand d-flex align-items-center justify-content-center">
				{/*<div className="sidebar-brand-icon rotate-n-15">*/}
				{/*	<i className="fas fa-laugh-wink" />*/}
				{/*</div>*/}
				<div className="d-md-none" id="brandShortName">
					<h6>GRS</h6>
				</div>
				<div className="sidebar-brand-text mx-3"><h6>Guest Review System</h6></div>
			</Link>
			<hr className="sidebar-divider my-0" />

			{Links.map((link, idx) => (
				<li className="nav-item" key={idx}>	 {/*{'nav-item' + (idx == 0 ? ' active' : '')}*/}
					{/*<a className="nav-link" href={link.link} >*/}
					{/*	<i className="fas fa-fw fa-chart-area" />*/}
					{/*	<span>{link.displayName}</span>*/}
					{/*</a>*/}
					<Nav.Link s={idx} as={Link}
						to={link.link}
						onClick={() => onSideNavItemClick(link.link)}>
						<i className={link.icon}/>
						<span className="menu-name-margin-left">{link.displayName}</span>
					</Nav.Link>
				</li>
			))}


			{/*<li className="nav-item active">*/}
			{/*	<a className="nav-link" href="index.html">*/}
			{/*		<i className="fas fa-fw fa-tachometer-alt" />*/}
			{/*		<span>Dashboard</span>*/}
			{/*	</a>*/}
			{/*</li>*/}
			{/*<hr className="sidebar-divider" />*/}

			<hr className="sidebar-divider d-none d-md-block" />
			<div className="text-center d-none d-md-inline">
				<button className="rounded-circle border-0" id="sidebarToggle" />
			</div>

		</ul>
    );
}