import * as React from 'react';

export const LayoutAnonymous: React.FC<{}> = props => {
    return (
        //<Container fluid="sm">
        //    <Row className="justify-content-md-center">
        //        <Col xs lg="3">
        //            {props.children}
        //            </Col>
        //        </Row>
        //</Container>

		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-10 col-lg-12 col-md-9">
					<div className="card o-hidden border-0 shadow-lg my-5">
						<div className="card-body p-0">
							<div className="row">
								<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
								<div className="col-lg-6 center-box" >
									<div className="p-5">
										<div className="text-center">
											<h1 className="h4 text-gray-900 mb-4">Guest Review System</h1>
										</div>

										{props.children}

										{/*<hr />*/}
										{/*<div className="text-center">*/}
										{/*	<a className="small" href="javascript:void(0);">Forgot Password?</a>*/}
										{/*</div>*/}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		
    )
}