import React, { useEffect, useState } from "react";
import { authenticationService } from '../../services/AuthenticationService';
import { HomeService } from "../../services/HomeService";
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function Home() {

    const [data, setData] = useState({
        propertyCount: 0,
        userCount: 0,
        reviewCount: 0,
        ownerCount: 0,
        managerCount: 0,
        staffCount: 0,
        userRole: 0,
        allowedManager: 0,
        allowedStaff: 0,
        propertyId: authenticationService.currentUserValue.propertyId,
        createdByUserId: authenticationService.currentUserValue.userId,
        
    });

    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isManager, setIsManager] = useState(false);    
    const [Links, setLinks] = useState(authenticationService.getAdminMenu());



    const formatUserCount = (isOwner, userCount, allowedUserCount) => {
        return (isOwner) ? userCount+"/" +allowedUserCount : userCount;
    };

    useEffect(() => {

        const currentUser = authenticationService.currentUserValue;
        
        if (currentUser != null) {
            switch (currentUser.userRole) {
                case 'Admin':
                case 'MasterAdmin':
                    data.userRole = 1;
                    setIsAdmin(true);
                    break;
                case 'Owner':
                    data.userRole = 2;
                    setIsOwner(true);
                    break;
                case 'Manager':
                    data.userRole = 3;
                    setIsManager(true);
                    break;
                case 'Staff':
                    data.userRole = 4;
                    break;
                default:
                    break;
            }
        }

        

        getDashboardData();

        async function getDashboardData() {
            //debugger;
            const response = await HomeService.getDashboardData(data.userRole, data.propertyId);
            setData(response.data.tResult);
        }
    }, []);

    return (
        <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>

                
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-4" style={isAdmin ? { display: 'block' } : { display:'none' }}>
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-info text-uppercase mb-1">
                                        Properties</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {/*<a href="/property">{data.propertyCount}</a>*/}
                                        {data.propertyCount}
                                    </div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-bed fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-md-4 mb-4" style={isAdmin ? { display: 'block' } : { display: 'none' }}>
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-warning text-uppercase mb-1">
                                        Users</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.userCount}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-users fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">
                                        Reviews</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.reviewCount}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-id-card fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4" style={(isAdmin || isOwner) ? { display: 'block' } : { display: 'none' }}>
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-info text-uppercase mb-1">
                                            Owner</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.ownerCount}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-users fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4" style={(isAdmin || isOwner) ? { display: 'block' } : { display: 'none' }}>
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-warning text-uppercase mb-1">
                                        Manager</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{formatUserCount(isOwner, data.managerCount, data.allowedManager)}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-users fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4" style={(isAdmin || isOwner || isManager) ? { display: 'block' } : { display: 'none' }}>
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center margin-dashboard-row-item">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">
                                        Staff</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{formatUserCount(isOwner, data.staffCount, data.allowedStaff)}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-users fa-2x text-gray-600"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}