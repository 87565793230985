import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './custom.css';
import Forbidden from './pages/Forbidden';
import Home from './pages/home/Home';
import Login from './pages/login/login';
import { LayoutAnonymous } from './pages/master-layout/LayoutAnonymous';
import { LayoutAuthenticated } from './pages/master-layout/LayoutAuthenticated';
import AddProperty from './pages/property/AddProperty';
import { EditProperty } from './pages/property/EditProperty';
import PropertyList from './pages/property/PropertyList';
import AddReviewAdv from './pages/review/AddReviewAdv';
import EditReview from './pages/review/EditReview';
import ReviewList from './pages/review/ReviewList';
import ReviewSearchAdv from './pages/review/ReviewSearchAdv';
import AddReviewTag from './pages/reviewtag/AddReviewTag';
import { EditReviewTag } from './pages/reviewtag/EditReviewTag';
import ReviewTagList from './pages/reviewtag/ReviewTagList';
import AddUser from './pages/user/AddUser';
import { EditUser } from './pages/user/EditUser';
import UserList from './pages/user/UserList';
import { PrivateRoute } from './utils/PrivateRoute';
import { PublicRoute } from './utils/PublicRoute';


const publicRoutes = [
    {
        key: "login",
        path: "/login",
        component: Login,
        exact: true
    }, {
        key: "forbidden",
        path: "/forbidden",
        component: Forbidden, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        skipAuth:true
    }
];

const privateRoutes = [
    {
        key: "home",
        path: "/",
        component: Home,
        exact: true,
        roles: 'MasterAdmin,Admin,Owner,Manager,Staff'
    },
    {
        key: "home",
        path: "/home",
        component: Home,
        exact: true,
        roles: 'MasterAdmin,Admin,Owner,Manager,Staff'
    },
    {
        key: "propertyList",
        path: "/property",
        component: PropertyList, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin'
    },
    {
        key: "addproperty",
        path: "/property/add",
        component: AddProperty, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles:'MasterAdmin,Admin'
    },{
        key: "updateproperty",
        path: "/property/update/:id",
        component: EditProperty, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin'
    },
    {
        key: "adduser",
        path: "/user/add",
        component: AddUser, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner'
    }, {
        key: "updateuser",
        path: "/user/update/:id",
        component: EditUser, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner'
    }, {
        key: "userlist",
        path: "/users",
        component: UserList, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner'
    },
    {
        key: "addreview",
        path: "/review/add",
        //component: AddReview, // sub routing is handled in that component
        component: AddReviewAdv, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner,Manager'
    },
    {
        key: "updatereview",
        path: "/review/update/:id",
        component: EditReview, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner,Manager'
    },
     {
        key: "review",
        path: "/reviews",
        component: ReviewList, // sub routing is handled in that component
         exact: true, // important, PageSettings is just a new Router switch container
         roles: 'MasterAdmin,Admin,Owner,Manager'
    }
    , {
        key: "reviewtagList",
        path: "/reviewtag",
        component: ReviewTagList, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin'
    },
    {
        key: "addreviewtag",
        path: "/reviewtag/add",
        component: AddReviewTag, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin'
    }, {
        key: "updatereviewtag",
        path: "/reviewtag/update/:id",
        component: EditReviewTag, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin'
    }, {
        key: "forbidden",
        path: "/forbidden",
        component: Forbidden, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: '*',
        skipAuth: true
    }, {
        key: "reviewsearch",
        path: "/review/search",
        //component: ReviewSearch, // sub routing is handled in that component
        component: ReviewSearchAdv, // sub routing is handled in that component
        exact: true, // important, PageSettings is just a new Router switch container
        roles: 'MasterAdmin,Admin,Owner,Manager,Staff'
    }
];
export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            currentLink: window.location.pathname, // == '/home' ? '' : window.location.pathname,
        };
    }

    addCurrentLink = (link) => {
        //debugger;
        this.setState({ currentLink : link});
    }

  render () {
    return (
        <Router>
            <Switch>
                <Route exact path={["/login", "/signup", "/forgot-password"]}>
                    <LayoutAnonymous>
                        <Switch>
                            {publicRoutes.map(publicRouteProps => (
                                <PublicRoute {...publicRouteProps} />
                            ))}
                        </Switch>
                    </LayoutAnonymous>
                </Route>
                <Route exact>
                    <LayoutAuthenticated addCurrentLink={this.addCurrentLink} currentLink={this.state.currentLink}>
                        <Switch>
                            {privateRoutes.map(privateRouteProps => (
                                <PrivateRoute {...privateRouteProps} addCurrentLink={this.addCurrentLink} currentLink={this.state.currentLink}/>
                            ))}
                        </Switch>
                    </LayoutAuthenticated>
                </Route>

                <Route exact path={["/property", "/property/*"]}>
                    <LayoutAuthenticated addCurrentLink={this.addCurrentLink} currentLink={this.state.currentLink}>
                        <Switch>
                            {privateRoutes.map(privateRouteProps => (
                                <PrivateRoute {...privateRouteProps} checkRole="MasterAdmin,Admin" addCurrentLink={this.addCurrentLink} currentLink={this.state.currentLink}/>
                            ))}
                        </Switch>
                    </LayoutAuthenticated>
                </Route>

                {/*<Route exact path={["/reviews", "/reviewlist", "/review/*", "/reviewtag","/reviewtag/*", "/property", "/property/*", "/users", "/user/*"]}>*/}
                {/*    <LayoutAuthenticated>*/}
                {/*        <Switch>*/}
                {/*            {privateRoutes.map(privateRouteProps => (*/}
                {/*                <PrivateRoute {...privateRouteProps} checkRole="MasterAdmin,Admin,Owner" />*/}
                {/*            ))}*/}
                {/*        </Switch>*/}
                {/*    </LayoutAuthenticated>*/}
                {/*</Route>*/}


                
            </Switch>
        </Router>
    );
  }
}
